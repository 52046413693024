import { useEffect, useState } from 'react';

interface Viewport {
  width: number;
}

export const useViewport = (): Viewport => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return (): void => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return { width };
};

export default useViewport;
