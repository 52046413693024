import {
  ICheckList,
  ICurrentProgess,
  IGetCandidateFullStatus,
  IOrderJobRole,
  IValidateCandidate,
} from '@gigin-work-space/model';
import moment from 'moment';

import {
  CheckoutStore,
  EmployerCDCStore,
  IPermissionStoreProps,
  permissionStore,
  permissions,
} from '@gigin-work-space/store';
import dayjs from 'dayjs';
import { digits } from './be-service-gateway-constant';
import {
  ADD_UPDATE_COMPANY_LOGO_PERMISSIONS,
  CREATE_ORDER_PERMISSIONS,
  CREATE_TEAM_PERMISSIONS,
  DELETE_TEAM_PERMISSIONS,
  LOCAL_NUMBER_LENGTH,
  MANAGE_TEAM_MEMBERS_PERMISSIONS,
  PRODUCTION_HOSTNAME,
  TypeExcel,
  TypeZip,
  UPDATE_TEAM_PERMISSIONS,
  VIEW_COMPANY_AGGREEMENT_PERMISSIONS,
  VIEW_COMPANY_PROFILE_PERMISSIONS,
  VIEW_PACKAGE_LIST_PERMISSIONS,
  VIEW_TEAM_DETAILS_PERMISSIONS,
  VIEW_TEAM_LIST_PERMISSIONS,
} from './constants';
import { COLORS } from './defined-styles';
import {
  BULK_UPLOAD_STATUS,
  BrowserStorageDataKeysEnum,
  CountryCodeEnums,
  EnumNotificationType,
  EnumNotificationUser,
  EnumWatiTemplate,
  Feature,
  FilterStatus,
  GenderType,
  OVERALL_STATUS,
  PROGRESS_STATUS,
  PermissionAction,
  ProtectedFileViewSourceEnum,
  ROLE_PERMISSION_CATEGORY_KEY,
  RedirectionAction,
  RedirectionLabel,
  RedirectionType,
} from './enums';
import { IRedirectionMeta, ViewProtectedImageSourceType } from './global-interface';
export function utils(): string {
  return 'utils';
}

export const pathSpliter = (text: string) => {
  return text.split('/').pop();
};

// isEmail
export const isValidEmail = (str: string): boolean => {
  // Use a regular expression to validate the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(str);
};

// valid mobile or not
export const isValidMobileNumber = (str: string): boolean => {
  const mobileRegex = /^(\+91|0)?[1-9][0-9]{9}$/;
  return mobileRegex.test(str);
};

export const isNotEmpty = (str: string): boolean => {
  return str.length > 0;
};
// import data resonse maker for candidate initiate
export const responseImportExcelDataMaker = ({ candidate, is_valid_row }: IValidateCandidate, id: number) => {
  return {
    id: id,
    name: candidate.name.value,
    email: candidate.email.value,
    mobile: candidate.mobile.value,
    // gender: candidate.gender.value || '',
    checkout_initiation_id: candidate.initiate_checkout_id,
    account_id: '',
    job_role: candidate.job_role.value,
    emailError: candidate.email.error_message,
    nameError: candidate.name.error_message,
    mobileError: candidate.mobile.error_message,
    job_roleError: candidate.job_role.error_message,
    isRowError: is_valid_row,
    isRowNew: false,
  };
};

export interface IResponseExcelDataMaker {
  id: number | undefined;
  name: string;
  email: string;
  mobile: string;
  checkout_initiation_id: string;
  account_id: string;
  job_role: string;
  emailError: string;
  nameError: string;
  mobileError: string;
  job_roleError: string;
  isRowError: boolean;
}
export const candidateCreateMaker = (
  name: string,
  email: string,
  mobile: string,
  job_role: string,
  checkout_initiation_id: string,
  packageId: string,
) => {
  return {
    account_id: CheckoutStore.account_id,
    checkout_initiation_id: checkout_initiation_id || CheckoutStore.checkout_initiation_id,
    name,
    email,
    mobile,
    job_role,
    isactive: true,
    packageId: packageId,
  };
};

// Color reutrn function
export const getStatusColor = (status: string): string => {
  switch (status?.toUpperCase()) {
    case PROGRESS_STATUS.SUCCESS:
      return COLORS.bk_secondary_active;
    case PROGRESS_STATUS.FAIL:
      return COLORS.bk_alert_warning;
    case PROGRESS_STATUS.BLOCKED:
      return COLORS.bk_alert_warning;
    case PROGRESS_STATUS.CANCELATION_REQUESTED:
      return COLORS.bk_alert_warning;
    case PROGRESS_STATUS.PENDING:
      return COLORS.bk_text_secondary;
    case PROGRESS_STATUS.INPROGRESS:
      return COLORS.bk_secondary_active;
    default:
      return COLORS.bk_secondary_active;
  }
};

// Background Color function
export const getStatusBgColor = (status: string): string => {
  switch (status?.toUpperCase()) {
    case PROGRESS_STATUS.SUCCESS:
      return COLORS.bk_bg_primary;
    case PROGRESS_STATUS.FAIL:
      return COLORS.bk_bg_primary;
    case PROGRESS_STATUS.PENDING:
      return COLORS.bk_tag_gray_bg;
    case PROGRESS_STATUS.INPROGRESS:
      return COLORS.bk_tag_blue_bg;
    default:
      return COLORS.bk_bg_primary;
  }
};

// accordion Color function
export const getAccordionColor = (value: number): string => {
  const modulusValue = value % 5;
  switch (modulusValue) {
    case 0:
      return COLORS.bk_tag_blue;
    case 1:
      return COLORS.bk_tag_purple;
    case 2:
      return COLORS.bk_tag_green;
    case 3:
      return COLORS.bk_action_pressed;
    default:
      return COLORS.bk_alert_warning;
  }
};

// accordion Color function by checkStatus
export const getAccordionColorByCheckStatus = (value: string): string => {
  switch (value?.toUpperCase()) {
    case OVERALL_STATUS.SUCCESS:
      return COLORS.bk_alert_success;
    case OVERALL_STATUS.FAIL:
      return COLORS.bk_alert_warning;
    case OVERALL_STATUS.BLOCKED:
      return COLORS.bk_alert_warning;
    case OVERALL_STATUS.INPROGRESS:
      return COLORS.bk_stroke_primary;
    default:
      return COLORS.bk_stroke_primary;
  }
};

// accordion Color function by checkStatus
export const getDescriptionBGColorByCheckStatus = (value: string): string => {
  switch (value?.toUpperCase()) {
    case OVERALL_STATUS.FAIL:
      return COLORS.bk_tag_orange_bg;
    default:
      return COLORS.bk_transparent;
  }
};

// return maximum 2 job role with count
export const maxTwoRole = (role: IOrderJobRole[]): string => {
  if (role.length === 0) return '';
  else if (role.length === 1) {
    return `${role[0].total_count} ${role[0].title}`;
  } else if (role.length === 2) {
    return `${role[0].total_count} ${role[0].title}, ${role[1].total_count} ${role[1].title}`;
  } else if (role.length > 2) {
    return `${role[0].total_count} ${role[0].title}, ${role[1].total_count} ${role[1].title} +${role.length - 2} more`;
  } else {
    return '';
  }
};

// getting complete status for each candidate
export const getCandidateFullStatus = ({ message, status, title }: ICurrentProgess): IGetCandidateFullStatus => {
  switch (status?.toUpperCase()) {
    case OVERALL_STATUS.INPROGRESS:
      return {
        title: title,
        bgColor: COLORS.bk_tag_gray_bg,
        color: COLORS.bk_text_secondary,
        icon: '',
        outline: COLORS.bk_bg_primary,
        status: status,
        message: message,
      };
    case OVERALL_STATUS.INSUFFICIENT:
      return {
        title: title,
        bgColor: COLORS.bk_tag_orange_bg,
        color: COLORS.bk_alert_new,
        icon: '',
        outline: COLORS.bk_alert_warning,
        status: status,
        message: message,
      };

    case OVERALL_STATUS.NO_RESPONSE:
      return {
        title: title,
        bgColor: COLORS.bk_tag_gray_bg,
        color: COLORS.bk_text_secondary,
        icon: '',
        outline: COLORS.bk_bg_primary,
        status: status,
        message: message,
      };

    case OVERALL_STATUS.BLOCKED:
      return {
        title: title,
        bgColor: COLORS.bk_bg_primary,
        color: COLORS.bk_text_secondary,
        icon: 'Warning',
        outline: COLORS.bk_alert_warning,
        status: status,
        message: message,
      };
    case OVERALL_STATUS.CANCELATION_REQUESTED:
      return {
        title: 'Cancelled',
        bgColor: COLORS.bk_bg_primary,
        color: COLORS.bk_text_secondary,
        icon: 'Warning',
        outline: COLORS.bk_alert_warning,
        status: status,
        message: message,
      };
    case OVERALL_STATUS.SUCCESS:
      return {
        title: 'Verifyin Green',
        bgColor: COLORS.bk_alert_success,
        color: COLORS.bk_bg_primary,
        icon: 'Success',
        outline: COLORS.bk_alert_success,
        status: status,
        message: message,
      };
    case OVERALL_STATUS.FAIL:
      return {
        title: 'Verifyin Red',
        bgColor: COLORS.bk_alert_failure,
        color: COLORS.bk_bg_primary,
        icon: 'Failure',
        outline: COLORS.bk_alert_failure,
        status: status,
        message: message,
      };

    default:
      return {
        title: title,
        bgColor: COLORS.bk_tag_gray_bg,
        color: COLORS.bk_text_secondary,
        icon: '',
        outline: COLORS.bk_bg_primary,
        status: status,
        message: message,
      };
  }
};

// getting limit Icon list
export const maxSevenIcons = (icon: ICheckList[]): boolean => {
  if (icon.length < 8) {
    return true;
  } else {
    return false;
  }
};

export const renderTemplate = (template: string, data: Record<string, any>, defaultValue: string = ''): string => {
  return template.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    return key in data ? data[key] : defaultValue;
  });
};

// date return with date and month format ex: 01 jan
export const getDateMonth = (stamp: number | string): string => {
  const timeStamp = stamp;
  // if (typeof stamp === 'string') timeStamp = parseInt(stamp);
  return moment(timeStamp).format('DD MMM');
};

export function refreshPage() {
  window.location.reload();
}

export const convertKiloBiteToMegaBite = (props: number | string) => {
  const fileSizeInBytes = Number(props);
  if (isNaN(fileSizeInBytes)) {
    return 'Invalid props value';
  }
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  const fileSizeInKB = fileSizeInBytes / 1024;

  if (fileSizeInMB < 1 && fileSizeInKB < 1) {
    return `${fileSizeInBytes} B`;
  }
  if (fileSizeInMB < 1) {
    return `${Math.round(fileSizeInMB * 1024)} KB`;
  }
  return `${Math.round(fileSizeInMB)} MB`;
  // return `${Math.floor(kilobytes / 1024)} KB`;
};

export const arrayHasValue = (arr: string[], value: string) => {
  return arr.includes(value);
};

export function getLastNDays(day: number): string {
  const toDate = dayjs().endOf('day').format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(day - 1, 'days')
    .startOf('day')
    .format('YYYY-MM-DD');
  return `${startDate}&${toDate}`;
}

export function capitalizeString(str: string) {
  return str?.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
}

// USER ROLE MAPPER
export const userRoleMap = (userRole: string) => {
  const ruleMap: Record<string, string> = {
    SUPER_ADMIN: 'Super Admin',
    ADMIN: 'Administrator',
    ACCOUNTS_FINANCE: 'Accounts & Finance',
    TEAM_LEAD: 'Team lead',
    TEAM_MEMBER: 'Team member',
    OPS_TEAM: 'OPS TEAM',
    TEMPORARY_MEMBER: 'Temporary member',
  };

  return ruleMap[userRole] || '';
};

// candidate STATUS MAPPER
export const orderStatusMap = (orderStatus: string) => {
  const statusMap: Record<string, string> = {
    INVITE_SEND: 'Invite sent',
    DATACOLLECTION: 'Data collection',
    VERIFICATION: 'Verification',
    QUALITY_CHECK: 'Quality check',
    REPORT: 'Report generated',
    'CANCELLATION REQUESTED': 'Cancelled',
  };

  return statusMap[orderStatus] || orderStatus.toString();
};

export const hasCheckStatusError = (status: string) => {
  return (
    status?.toUpperCase() === PROGRESS_STATUS.INPROGRESS ||
    // status.toUpperCase() === PROGRESS_STATUS.FAIL ||
    status?.toUpperCase() === PROGRESS_STATUS.BLOCKED
  );
};
// function to return singular or plural string based on value send in parameter
export const pluralizeString = (stringValue: string, targetValue: number) => {
  if (targetValue <= 1) return stringValue;
  return stringValue.concat('s');
};

// fuction to map permissions in ui with components shown in UI
export const componentAccessibility = (componentUsage: string) => {
  const componentAccessibilityMap: Record<string, any> = {
    CREATE_ORDER: CREATE_ORDER_PERMISSIONS,
    ADD_UPDATE_COMPANY_LOGO: ADD_UPDATE_COMPANY_LOGO_PERMISSIONS,
    VIEW_COMPANY_PROFILE: VIEW_COMPANY_PROFILE_PERMISSIONS,
    VIEW_COMPANY_AGGREEMENT: VIEW_COMPANY_AGGREEMENT_PERMISSIONS,
    CREATE_TEAM: CREATE_TEAM_PERMISSIONS,
    UPDATE_TEAM: UPDATE_TEAM_PERMISSIONS,
    MANAGE_TEAM_MEMBERS: MANAGE_TEAM_MEMBERS_PERMISSIONS,
    DELETE_TEAM: DELETE_TEAM_PERMISSIONS,
    VIEW_TEAM_LIST: VIEW_TEAM_LIST_PERMISSIONS,
    VIEW_TEAM_DETAILS: VIEW_TEAM_DETAILS_PERMISSIONS,
    VIEW_PACKAGE_LIST: VIEW_PACKAGE_LIST_PERMISSIONS,
  };

  return componentAccessibilityMap[componentUsage] || null;
};

// storing localstorge for tab
export const currentSettingsTab = () => {
  return localStorage.getItem(BrowserStorageDataKeysEnum.COMPANY_SETTINGS_TAB) ?? 'companyProfile';
};

// Check user have permission or not
export const hasPermission = (category: ROLE_PERMISSION_CATEGORY_KEY, feature: Feature, action: PermissionAction) => {
  const userPermission: any = localStorage.getItem(BrowserStorageDataKeysEnum.USER_PERMISSIONS);
  const rolePermission: any = userPermission && userPermission !== 'undefined' ? JSON.parse(userPermission) : null;

  if (category && feature && action) {
    return (
      (rolePermission &&
        rolePermission[category] &&
        rolePermission[category][feature] &&
        rolePermission[category][feature]?.includes(action)) ??
      false
    );
  }

  return false;
};

export const isBlockedorFailed = (status: string) => {
  return status.toUpperCase() === OVERALL_STATUS.FAIL || status.toUpperCase() === OVERALL_STATUS.BLOCKED;
};

// guarded Navigate
export const guardedPageNavigate = () => {
  const {
    canCreateOrder,
    canUpdateOrder,
    canReadOrder,
    canReadCompanyProfile,
    canCreateCompanyProfile,
    canUpdateCompanyProfile,
    canReadAnalytics,
  } = permissionStore;
  return canAccess([canCreateOrder, canReadOrder, canUpdateOrder])
    ? '/bgv'
    : canAccess([canReadCompanyProfile, canCreateCompanyProfile, canUpdateCompanyProfile])
    ? '/bgv/company-settings'
    : canAccess([canReadAnalytics])
    ? '/bgv/analytics'
    : '/bgv';
};

// verification tab returner
export const guardedVerificationTab = () => {
  const validTab: string[] = [];
  validTab.push('Verification');
  validTab.push('Analytics');
  return validTab;
};

// Settings tab returner

export const guardedSettingsTab = () => {
  const { canReadGroupOfCompanies } = permissionStore;

  const validTab: string[] = [];
  validTab.push('Company settings');
  if (canAccess([canReadGroupOfCompanies])) {
    validTab.push('Organization List');
  }
  return validTab;
};

// Ops tab returner
export const guardedOpsTab = () => {
  const validTab: string[] = [];
  hasPermission(ROLE_PERMISSION_CATEGORY_KEY.OPS_MANAGEMENT, Feature.OPS, PermissionAction.READ) &&
    validTab.push('Ops Platform');
  return validTab;
};

// Check token available or not
export const verifyToken = () => {
  const userToken = localStorage.getItem(BrowserStorageDataKeysEnum.AUTH_TOKEN) ?? '';
  return userToken.length ? true : false;
};

export const canAccess = (permissions: boolean[]) => {
  return permissions.some((permission) => permission === true);
};

export function formatDateFromTimestamp(timestamp: string, format: string): string {
  const formattedDate = dayjs(timestamp).format(format ? format : 'DD MMM');
  return formattedDate;
}

export const findActiveStep = (index: number): boolean => {
  return EmployerCDCStore.activeStep === index;
};

export const findCompletedStep = (index: number): boolean => {
  return EmployerCDCStore.stepperStatus.find((item) => item.step === index)?.progress === BULK_UPLOAD_STATUS.COMPLETED;
  // return EmployerCDCStore.stepperStatus[index].progress === 'complete';
};

export const firstLetterReturner = (str: string): string => {
  return str[0];
};

export function getLastSixCharacters(inputString: string) {
  if (inputString?.length >= 6) {
    return inputString?.slice(-6);
  } else {
    // Handle cases where the input string has fewer than 6 characters
    return inputString;
  }
}

export const returnIconExcelOrZip = (type: string) => {
  if (type === TypeExcel) return 'xl-icon.svg';
  else if (type === TypeZip) return 'zip-icon.svg';
  return '';
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const extractFieldFromArray = (data: any[], fieldName: string) => {
  return data.map((application) => application[fieldName]);
};

export const createIdPairsPairs = (singleId: string, IdList: string[]): { Id: string; singleId: string }[] => {
  if (!singleId || !Array.isArray(IdList)) {
    const msg = `Invalid input: singleId must be a string and IdList must be an array of strings for ${JSON.stringify(
      IdList,
    )} ${singleId}`;
    //TODO use logger
    console.error(msg);

    return [];
  }

  const jobTalentPairs: { Id: string; singleId: string }[] = [];

  for (const Id of IdList) {
    const pair = { Id, singleId };
    jobTalentPairs.push(pair);
  }

  return jobTalentPairs;
};

export const getLocalStorage = (item: string) => {
  return JSON.parse(localStorage.getItem(item) || '');
};

export const setLocalStorage = (item: string, data: any) => {
  localStorage.setItem(item, JSON.stringify(data));
};

export const convertToEpocTime = (date: string) => {
  return new Date(date).getTime() / 1000;
};

export const generateOTP = () => {
  let OTP = '';
  for (let i = 0; i < 6; i++) {
    OTP += digits[Math.floor(Math.random() * 10)];
  }
  return OTP;
};

// Utility function to fetch the file with authorization
export const fetchFileWithAuth = async (url: string, source: ViewProtectedImageSourceType): Promise<Blob> => {
  const auth_token =
    source === ProtectedFileViewSourceEnum.SESSION_STORAGE
      ? sessionStorage.getItem(BrowserStorageDataKeysEnum.AUTH_TOKEN)
      : localStorage.getItem(BrowserStorageDataKeysEnum.AUTH_TOKEN);
  const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';
  let token = null;

  if (trimmed_token) {
    token = `${trimmed_token}`;
  }
  const headers = new Headers();
  const authToken = token;
  headers.append('Authorization', `Bearer ${authToken}`);

  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.blob();
};

// specifications/specification.interface.ts
export interface HiringSpecification<T> {
  isSatisfiedBy(item: T): boolean;
  toQuery(): any;
  and(spec: HiringSpecification<T>): HiringSpecification<T>;
  or(spec: HiringSpecification<T>): HiringSpecification<T>;
  not(): HiringSpecification<T>;
}

export interface NotificationTemplate {
  userType: EnumNotificationUser;
  notiType: EnumNotificationType;
  name: string;
  from?: string;
  subject?: string;
  header?: string;
  body?: string;
  footer?: string;
  metaData?: PushMetaData | WhatsAppMetaData;
  channels?: EnumNotificationType;
  whatsapp?: EnumWatiTemplate;
  title?: string;
  sentence?: string;
  params?: object;
}

export interface PushMetaData {
  Ui_dist: Array<{ cordova: string }>;
  info?: Array<{ type: string; value: string }>;
  actionInfo: string;
  action: RedirectionAction;
  actionText: RedirectionLabel;
  actionSubText?: RedirectionLabel;
  meta?: IRedirectionMeta;
  type?: RedirectionType;
}

export interface WhatsAppMetaData {
  info: Array<{ type: string; value: string }>;
  meta?: IRedirectionMeta;
}
export const formatString = async (template: string, ...values: string[]): Promise<string> => {
  return template.replace(/%s/g, () => values.shift() || '');
};

export const profileImageSelector = (profilePicUrl: string, gender: string) => {
  if (profilePicUrl) {
    return profilePicUrl;
  }

  if (gender && GenderType.MALE === gender.toLowerCase()) {
    return 'https://cdn-assets.ai.gigin.ai/assets-v4/img/male.svg';
  }

  if (gender && GenderType.FEMALE === gender.toLowerCase()) {
    return 'https://cdn-assets.ai.gigin.ai/assets-v4/img/female.svg';
  }

  // Default to male image if gender is not recognized or provided
  return 'https://cdn-assets.ai.gigin.ai/assets-v4/img/male.svg';
};

export const maskingPatternMap: Record<string, RegExp> = {
  aadhaar_number: /^(\*+)(\d{4})$/, // last 4 digits unmasked
  pancard_card_number: /^([A-Z0-9]{3})(\*+)([A-Z0-9]{1})$/, // first 3 and last 1 unmasked
  voter_card_number: /^([A-Z0-9]{2})(\*+)([A-Z0-9]{2})$/, // first 2 and last 2 unmasked
  driving_licence_number: /^([A-Z0-9-]{3})(\*+)([A-Z0-9]{4})$/, // first 3 and last 4 unmasked
  passport_number: /^([A-Z0-9]{1})(\*+)([A-Z0-9]{2})$/, // first 1 and last 2 unmasked
  passport_file_no: /^([A-Z0-9]{1})(\*+)([A-Z0-9]{2})$/, // first 1 and last 2 unmasked
};

export const isValueMasked = (value: string, fieldName: string): boolean => {
  // const maskingPatternMap: Record<string, RegExp> = {
  //   aadhaar_number: /^(\*+)(\d{4})$/, // last 4 digits unmasked
  //   pancard_card_number: /^([A-Z0-9]{3})(\*+)([A-Z0-9]{1})$/, // first 3 and last 1 unmasked
  //   voter_card_number: /^([A-Z0-9]{2})(\*+)([A-Z0-9]{2})$/, // first 2 and last 2 unmasked
  //   driving_licence_number: /^([A-Z0-9-]{3})(\*+)([A-Z0-9]{4})$/, // first 3 and last 4 unmasked
  //   passport_number: /^([A-Z0-9]{1})(\*+)([A-Z0-9]{2})$/, // first 1 and last 2 unmasked
  //   passport_file_no: /^([A-Z0-9]{1})(\*+)([A-Z0-9]{2})$/, // first 1 and last 2 unmasked
  // };

  const pattern = maskingPatternMap[fieldName];
  if (!pattern) {
    return false; // Return false if there's no masking pattern for the given field
  }

  return pattern.test(value); // Check if the value matches the masking pattern
};

export const cleanEmail = (email: string): string => {
  // Regex to keep only valid email characters (letters, digits, ., -, _, @)
  return email?.replace(/[^a-zA-Z0-9._@-]/g, '');
};

/**
 * Checks if the application is running in a production environment.
 *
 * @returns {boolean} - Returns true if the application is in production, otherwise false.
 */
export function isProduction(): boolean {
  const productionHostname = PRODUCTION_HOSTNAME;
  return window.location.hostname === productionHostname;
}

/**
 * Converts a sentence to camel case format.
 * Each word in the sentence will have its first letter capitalized and the rest in lowercase.
 *
 * @param {string} sentence - The input sentence to be converted.
 * @returns {string} - The camel case formatted string.
 */
export const toCapitalize = (sentence: string): string => {
  if (!sentence) return '';

  return sentence
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

/**
 * Parses the query parameters from a URL search string and returns them as an object.
 *
 * @param {string} search - The URL search string (e.g., "?param1=value1&param2=value2").
 * @returns {Record<string, string>} - An object containing the query parameters as key-value pairs.
 */
export const parseQueryParams = (search: string): Record<string, string> => {
  const params = new URLSearchParams(search);
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Record<string, string>);
};

/**
 * Checks if the difference between the given epoch time and the current system time
 * is more than the specified number of minutes.
 *
 * @param {number} epochTime - The epoch time to compare.
 * @param {number} minutes - The number of minutes to check the difference against.
 * @returns {boolean} - Returns true if the difference is more than the specified minutes, otherwise false.
 */
export const isLinkExpired = (epochTime: number, minutes: number): boolean => {
  const currentTime = Date.now();
  const differenceInMilliseconds = currentTime - epochTime;
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  return differenceInMinutes > minutes;
};

/**
 * Extracts the first word from a given string, capitalizes the first letter,
 * and converts the rest to lowercase. The word is split based on special characters,
 * spaces, or the '@' symbol.
 *
 * @param {string} input - The input string to process.
 * @returns {string} - The formatted first word.
 */
export function extractAndFormatFirstWord(input: string): string {
  if (!input) return '';

  const firstWord = input.split(/[@\s\W]/)[0];
  return firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
}

// Map for country code lengths
export const COUNTRY_CODE_LENGTHS: Record<string, number> = {
  [CountryCodeEnums.INDIA]: 3,
  [CountryCodeEnums.USA]: 2,
};

export function formatPhoneNumber(
  phoneNumber: string,
  defaultCountryCode: CountryCodeEnums = CountryCodeEnums.INDIA,
): string {
  if (!phoneNumber) return '';

  // Remove all non-digit characters except the leading '+'
  const cleaned = phoneNumber.replace(/(?!^\+)\D/g, '');

  // Initialize variables for country code and local number
  let countryCode = '';
  let localNumber = '';

  // Determine if the number has a leading '+'
  const hasPlusSign = cleaned.startsWith('+');

  if (hasPlusSign) {
    // Extract country code based on known lengths
    for (const [code, length] of Object.entries(COUNTRY_CODE_LENGTHS)) {
      if (cleaned.startsWith(code)) {
        countryCode = code;
        localNumber = cleaned.slice(length);
        break;
      }
    }
  } else if (cleaned.length > LOCAL_NUMBER_LENGTH) {
    // Assume the first digits are the country code if the number is longer than the local number
    countryCode = `+${cleaned.slice(0, cleaned.length - LOCAL_NUMBER_LENGTH)}`;
    localNumber = cleaned.slice(cleaned.length - LOCAL_NUMBER_LENGTH);
  } else {
    // Default to the specified country code if none is provided
    countryCode = defaultCountryCode;
    localNumber = cleaned;
  }

  // Format the number with a space after the country code
  return `${countryCode} ${localNumber}`;
}

// Function to update userPermissions in permissionStore
export const updatePermissions = () => {
  permissions.forEach(({ key, feature, action, permissionKey }) => {
    permissionStore[permissionKey as keyof IPermissionStoreProps] = hasPermission(key, feature, action);
  });

  permissionStore['hasToken'] = verifyToken();
};

// Function to check if the report status is a terminal status or not
export const isReportStatusTerminal = (status: FilterStatus) => {
  const reportTerminalStatuses = [
    FilterStatus.CLEAR,
    FilterStatus.DISCREPANT,
    FilterStatus.NO_RESPONSE,
    FilterStatus.UNABLE_TO_VERIFY,
  ];
  return reportTerminalStatuses.includes(status);
};
