import {
  createPayloadBody,
  selectedAnyCheckStore,
  setSaveDataPayload,
  setSelectedAnyCheck,
} from '@gigin-work-space/store';
import { Grid, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { BKStyledSelect, BKStyledSelectGroup } from '../../../utils';
import { FormFieldGenerator } from '../form-field-generator';

export interface SelectAnyCheckFieldProps {
  params: any;
}

export const SelectAnyCheckField = (props: SelectAnyCheckFieldProps) => {
  const { params } = props;
  const { selectedAnyCheck } = useSnapshot(selectedAnyCheckStore);
  const [value, setValue] = useState(selectedAnyCheck.value ? selectedAnyCheck.value : '');
  const navigate = useNavigate();
  const navParam = useParams();

  //   function to handle text change
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSaveDataPayload([]);
    const valueSelected = event.target.value as string;
    setValue(valueSelected);

    // change route of url to slected check
    const selectedParam = Object.values(navParam)[0]?.split('/');
    selectedParam?.splice(1, 1, valueSelected);
    const newParam = selectedParam?.join('/');
    navigate(`/collect-data/${newParam}`);

    // set store value
    const selectedCheck = params.filter((data: any) => data.value === valueSelected)[0];
    setSelectedAnyCheck(selectedCheck);
    createPayloadBody(selectedCheck?.fields);
  };

  return (
    <>
      {/* TODO: replace en with current language selected symbol */}
      <BKStyledSelectGroup fullWidth>
        <InputLabel id="simple-select-label">Select Any Check</InputLabel>
        <BKStyledSelect
          labelId="simple-select-label"
          id="simple-select"
          value={value}
          label="Select any check"
          onChange={handleChange}>
          {params.map((menu: any) => (
            <MenuItem value={menu.value} key={menu.value}>
              {menu.title.en}
            </MenuItem>
          ))}
        </BKStyledSelect>
      </BKStyledSelectGroup>
      {/* Section to render dropdown selected option field parameters */}
      <section className="mt-6">
        <Grid container spacing={2}>
          {selectedAnyCheck.fields &&
            selectedAnyCheck.fields.map((field: any) => (
              <Grid item xs={field.grid ? field.grid : 12} key={field.field_id}>
                <FormFieldGenerator params={field} />
              </Grid>
            ))}
        </Grid>
      </section>
    </>
  );
};
