import { Schema as MongooseSchema } from 'mongoose';
import { genNanoId } from './common-method';
import { HIRING_USER_ACTIVITY_TYPE, RecruitmentUserRole } from './enums';

export const AddressSchema = new MongooseSchema({
  city: { type: String, default: null },
  state: { type: String, default: null },
  country: { type: String, default: null },
  google_place_id: { type: String, default: null },
  zip: { type: String, default: null },
  geolocation: {
    latitude: { type: String, default: null },
    longitude: { type: String, default: null },
  },
  location_id: { type: String, default: null }, //TODO
  address_line_1: { type: String, default: null },
  address_line_2: { type: String, default: null },
  addressFormat: { type: String, default: null },
  locality: { type: String, default: null },
});

export const UserAccountActivity = new MongooseSchema({
  activityId: { type: String, default: () => `UAAC-${genNanoId(16)}` },
  legacySqlId: { type: String, default: null },
  userId: { type: String },
  userRole: { type: String, enum: RecruitmentUserRole },
  activityType: { type: String, enum: HIRING_USER_ACTIVITY_TYPE, default: null },
  activityData: { type: String, default: null },
  deviceInfo: { type: String, default: null },
  service: { type: String, default: null },
  status: { type: String, default: null },
  timestamp: { type: Date, default: Date.now },
});
