import {
  addressCBUsageStore,
  createPayloadBody,
  handleUpdateFieldParameter,
  saveDataPayloadStore,
  selectedAnyCheckStore,
  setAddressCheckBoxUsage,
  setCheckOtpVerified,
  setSaveDataPayload,
  setSelectedAnyCheck,
} from '@gigin-work-space/store';
import {
  AADHAAR_NUMBER,
  COLLECTION_GROUP_TYPE,
  CURRENTLY_WORKING_HERE,
  EMPLOYMENT_END_DATE,
  FILE,
  isValueMasked,
  SESSION_KEYS,
  SINGLE_SELECT,
  useViewport,
} from '@gigin-work-space/utils';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { snapshot, useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../services';
import { applyRuleMap, BKStyledButton, StyledRelGroupModal } from '../../utils';
import { CommonLoader, DisplayImage, Footer, Header, MetaDetailsContainer, VerifyOtp } from '../common-ui';
import { CommonBottomDrawerHoc } from './common-bottom-drawer-hoc';
import { EditableContainerHoc } from './editable-container-hoc';
import { ViewableContainerHoc } from './viewable-container-hoc';

interface ValidationError {
  fieldName: string;
  fieldId: string;
  errorMessage: string;
}

interface Field {
  value: string;
  display_text: {
    en: string;
    hi: string;
  };
}

interface FieldsArray {
  fields: Field[];
}
export const DataCollectionHoc = () => {
  const { selectedAnyCheck } = useSnapshot(selectedAnyCheckStore);
  const { addressCBValue } = useSnapshot(addressCBUsageStore);
  const { width } = useViewport();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [navParam, setNavParam] = useState(Object.values(useParams())[0]);
  const [section, setSection] = useState<any>();
  const [bodyParameter, setBodyParameter] = useState<any>();
  const [isConfirmRequired, setIsConfirmRequired] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [relGroupModalOpen, setRelGroupModalOpen] = useState(false);
  const [otpVerificationToken, setOtpVerificationToken] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [validationError, setValidationError] = useState<ValidationError[]>([]);

  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID);

  //   Function to get Page Layout
  const getLayoutDetails = useCallback(async () => {
    try {
      setIsPageLoading(true);
      setSaveDataPayload([]);
      const response = await axiosInstance.get(`${endpoints.GET_BODY_PARAMETER}/${navParam}`);
      if (response.data.success) {
        setSection(response.data.data.data.section);
        setBodyParameter(response.data.data.data.rel_data);
        createPayloadBody(response.data.data.data.rel_data.fields);
      }
    } catch (error) {
      enqueueSnackbar('Failed to get body parameter', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setIsPageLoading(false);
    }
  }, [navParam]);

  interface Field {
    field_data_type: string;
    field_id: string;
  }
  //Check All the documents are uploaded
  const handleSaveAndPreview = async () => {
    const isChanged = await isAadhaarNumberChanged();
    if (isChanged) {
      return;
    }
    const payloadData = snapshot(saveDataPayloadStore).saveDataPayload;
    const validationResult = await isSaveDataPayloadValidToSave();

    if (validationResult === false) {
      // Proceed to the next step
      return false;
    }

    const isTrue = bodyParameter.fields.some((f: Field) => {
      if (
        f.field_data_type === FILE &&
        COLLECTION_GROUP_TYPE.employment_detail === bodyParameter.rel_group_detail.title.en //NOTE: Model will display only for Employment check
      ) {
        const data = payloadData.find((item) => item.id === f.field_id);
        return !data?.dataCollected.value;
      }
      return false;
    });

    if (isTrue) {
      setOpenDialog(true);
    } else {
      handleSaveAndContinue();
    }
  };

  const handleConfirmSaveData = async () => {
    await handleSaveAndContinue();
    dataValidation();
    setOpenDialog(false);
  };

  //   Function to handle Save and Continue CTA
  const handleSaveAndContinue = async () => {
    // TODO: handle api success fail scenarios

    try {
      setIsApiLoading(true);
      const payloadBody = snapshot(saveDataPayloadStore).saveDataPayload;
      const response = await axiosInstance.patch(`${endpoints.PATCH_SAVE_AND_CONTINUE}`, payloadBody);
      if (response.data.success) {
        if (bodyParameter?.rel_id === 'any' && selectedAnyCheck?.fields?.length > 0) {
          const updatedFields = snapshot(selectedAnyCheckStore).selectedAnyCheck?.fields?.map((field: any) => {
            const newValue = response.data.data.filter((data: any) => data._id === field.field_id)[0]?.data_collected;

            return { ...field, field_value: newValue };
          });
          const updatedSelectedCheck = {
            ...snapshot(selectedAnyCheckStore).selectedAnyCheck,
            fields: updatedFields,
          };

          setSelectedAnyCheck(updatedSelectedCheck);
        }
        handleGotoNav();
      }
    } catch (error) {
      (error as any).response.data.message.map((item: ValidationError) => {
        enqueueSnackbar(`${item.errorMessage}`, {
          variant: 'error',
          autoHideDuration: 2000,
          key: item.fieldId,
        });
      });

      setValidationError((error as any).response.data.message);
    } finally {
      setIsApiLoading(false);
      dataValidation();
    }
  };

  // function to open modal and generate otp
  const handleGenerateOtp = async () => {
    try {
      const validationResult = await isSaveDataPayloadValidToSave();

      if (validationResult === false) {
        // Proceed to the next step
        return false;
      }
      setIsApiLoading(true);
      const body = { data_collection_id: dataCollectionId };
      const response = await axiosInstance.post(`${endpoints.GET_AADHAAR_OTP}`, body);
      if (response.data.success) {
        setOtpVerificationToken(response.data.data.verification_token);
        setOtpModalOpen(true);
      } else {
        throw new Error('failed to send otp');
      }
    } catch (error) {
      setOtpModalOpen(false);
      enqueueSnackbar('Failed to send otp', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  // If Aadhaar number changes after otp verify then user need to re-verify aadhaar otp
  const isAadhaarNumberChanged = async () => {
    const payloadData = snapshot(saveDataPayloadStore).saveDataPayload;
    const allFieldList = allFields();
    let isChanged = false;

    for (const field of allFieldList) {
      if (field.field_name === AADHAAR_NUMBER) {
        const payloadItem = payloadData.find((p) => p.id === field.field_id);
        if (payloadItem && payloadItem.dataCollected.value !== field.field_value.value) {
          isChanged = true;
          await handleGenerateOtp();
          enqueueSnackbar('Aadhaar Number Changed: Please Verify Your OTP.', {
            variant: 'info',
            autoHideDuration: 4000,
          });
          break;
        }
      }
    }

    return isChanged;
  };

  //   Function to handle Goto navigation on successfully saving the details
  const handleGotoNav = () => {
    // If confirm page is required
    if (bodyParameter && bodyParameter?.access?.confirmation_required) {
      setIsConfirmRequired(true);
    }
    // otherwise confirm page is not required
    else {
      setIsConfirmRequired(false);
      navigate('/dashboard');
    }
  };

  // function to handle confirm cta
  const handleConfirm = useCallback(async () => {
    // dynamic param created for confirmation api
    const confirmationParam = navParam?.split('/').slice(1).join('/');
    const currentRelId = bodyParameter?.rel_id === 'any' ? selectedAnyCheck?.rel_id : bodyParameter?.rel_id;
    const currentActiveCheck = sortedSectionList?.findIndex((data: any) => data?.rel_id === currentRelId);
    // TODO: handle api success failure cases
    try {
      setIsApiLoading(true);
      const response = await axiosInstance.patch(`${endpoints.PATCH_CONFIRM_DETAILS}/${confirmationParam}`, {});

      if (response.data.success) {
        setIsConfirmRequired(false);
        if (sortedSectionList?.length > 0) {
          const nextStepIndex = sortedSectionList?.findIndex(
            (obj: any) => obj.status?.toLowerCase() !== 'completed' && obj.meta.goto.destination !== navParam,
          );
          if (nextStepIndex !== -1) {
            enqueueSnackbar(`${sortedSectionList[currentActiveCheck]?.title?.en} details confirmed`, {
              variant: 'success',
              autoHideDuration: 2000,
            });
            setNavParam(sortedSectionList[nextStepIndex].meta.goto.destination);
          } else {
            setRelGroupModalOpen(true);
          }
        } else {
          setRelGroupModalOpen(true);
        }
      }
    } catch (error) {
      enqueueSnackbar('Fail to confirm details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setIsApiLoading(false);
      setSaveDataPayload([]);
      setAddressCheckBoxUsage(false, {});
      setConfirmModalOpen(false);
      setSelectedAnyCheck({});
    }
  }, [section]);

  // function to close Rel Group Completion Modal and navigate to dashboard
  const handleCloseRelGroupModal = () => {
    setRelGroupModalOpen(false);
    navigate('/dashboard');
  };

  // function to handle step clicked navigation
  const handleStepClick = (param: any) => {
    setSaveDataPayload([]);
    setSelectedAnyCheck({});
    setAddressCheckBoxUsage(false, {});
    setNavParam(param);
    navigate(`/collect-data/${param}`);
  };

  // function to update the parent and close the modal
  const updateAndCloseModal = () => {
    setCheckOtpVerified(true);
    setIsUpdate(true);
    setOtpModalOpen(false);
    handleSaveAndContinue();
  };

  // function to return all required fields
  const allFields = () => {
    if (bodyParameter?.rel_id === 'any') {
      return selectedAnyCheck?.fields;
    }
    return bodyParameter?.fields;
  };

  const allFieldsData = () => {
    if (bodyParameter?.rel_id === 'any') {
      return selectedAnyCheck?.fields?.filter((field: any) => field?.validation?.is_optional);
    }
    return bodyParameter?.fields?.filter((field: any) => field?.validation?.is_optional);
  };

  const dataValidation = async () => {
    const allData = await allFieldsData();
    validationError.forEach((item) => {
      allData.forEach((field: { field_id: string }) => {
        if (item.fieldId === field.field_id) focusOnErrorField(field);
      });
    });
  };

  const [fieldErrors, setFieldErrors] = useState({});

  const isSaveDataPayloadValidToSave = async () => {
    const payloadData = snapshot(saveDataPayloadStore).saveDataPayload;
    const allFieldList = allFields();

    let isValid = true;
    let skipEndDate = false;
    for (const field of allFieldList) {
      const payload = payloadData.find((p) => p.id === field.field_id);
      // Continue if payload is not found
      if (!payload) continue;
      // Check if the value is null or empty
      const trimmedValue = payload.dataCollected.value?.trim();

      // Skip validation if value is masked
      if (field.should_encrypt_hash && isValueMasked(trimmedValue, field.field_name)) {
        continue;
      }

      if ((trimmedValue === '' || trimmedValue === undefined) && field.validation.is_optional) continue;
      const options: FieldsArray = field.option.data;
      if (field.validation.rule === SINGLE_SELECT) {
        const matchingItem = options?.fields.find((item) => trimmedValue === item.value);
        if (matchingItem) {
          continue;
        } else {
          isValid = false;
        }
      }

      if (field.validation.rule === CURRENTLY_WORKING_HERE && trimmedValue === 'Yes') {
        skipEndDate = true;
      }
      if (skipEndDate && EMPLOYMENT_END_DATE.includes(field?.field_name)) {
        continue;
      }
      if (field.validation.rule === SINGLE_SELECT) {
        const matchingItem = options?.fields.find((item) => trimmedValue === item.value);
        if (matchingItem) {
          continue;
        } else {
          isValid = false;
        }
      }

      const ruleMap = await applyRuleMap(field?.validation?.rule);

      try {
        await ruleMap.validate({ value: trimmedValue });

        setFieldErrors((prevErrors) => ({ ...prevErrors, [field.field_name]: '' })); // Clear error
      } catch (error) {
        //TODO: handle error from rule
        focusOnErrorField(field);
        isValid = false;
      }
    }

    return isValid;
  };

  const focusOnErrorField = (field: any) => {
    let fieldElement: HTMLElement | null;

    if (field.field_data_type === FILE) {
      fieldElement = document.getElementById(field.field_id) as HTMLElement;
      if (fieldElement) {
        //fieldElement.style.borderColor = '2px solid red'; // Change border color to red
        fieldElement.style.border = '2px solid red'; // Change border color to red
      }
    } else {
      // For other data types, select the field by name
      fieldElement = document.querySelector(`[name='${field.field_name}']`) as HTMLElement;
    }

    if (fieldElement && typeof fieldElement.focus === 'function') {
      fieldElement.focus();
      // Optionally blur if needed
      fieldElement.blur();
    }
  };

  // function to return footer btn mode - save, confirm, otp, null
  const footerBtnMode = () => {
    if (bodyParameter?.access?.mode !== 'edit') {
      return null;
    }

    if (isConfirmRequired) {
      return 'confirm';
    }

    if (
      (!isUpdate && bodyParameter?.collection_info?.is_otp_required && !bodyParameter?.collection_info?.otp_verified) ||
      (bodyParameter?.rel_id === 'any' &&
        !isUpdate &&
        selectedAnyCheck?.collection_info?.is_otp_required &&
        !selectedAnyCheck?.collection_info?.otp_verified)
    ) {
      return 'otp';
    }

    return 'save';
  };

  // Function to create new bodyParameter / selected any check when address checkbox is toggled on
  const copyParameterValue = (field: any) => {
    const updatedFieldValue = snapshot(addressCBUsageStore).allFieldsValue?.filter(
      (data: any) => data.field_id === field.field_id,
    )[0]?.field_value;
    if (field?.field_data_type !== 'address_checkbox')
      return updatedFieldValue ? { ...field, field_value: updatedFieldValue } : field;
    return { ...field, field_value: { value: addressCBValue ? 'Yes' : 'No', type: 'address_checkbox' } };
  };
  // Function to clear bodyParameter / selected any check when address checkbox is toggled off
  const clearParameterValue = (field: any) => {
    if (field?.field_data_type !== 'address_checkbox') return { ...field, field_value: '' };
    return { ...field, field_value: { value: addressCBValue ? 'Yes' : 'No', type: 'address_checkbox' } };
  };

  //   SIDE EFFECTS
  useEffect(() => {
    getLayoutDetails();
  }, [getLayoutDetails, isConfirmRequired]);

  useMemo(() => {
    if (
      (bodyParameter?.collection_info?.is_otp_required && bodyParameter?.collection_info?.otp_verified) ||
      (bodyParameter?.rel_id === 'any' &&
        selectedAnyCheck?.collection_info?.is_otp_required &&
        selectedAnyCheck?.collection_info?.otp_verified)
    ) {
      setCheckOtpVerified(true);
      return;
    }
    setCheckOtpVerified(false);
  }, [bodyParameter, selectedAnyCheck]);

  // Memoized effect to sort section and fields based on order
  const sortedSectionList = useMemo(() => {
    return section?.sectionList?.sort((obj1: any, obj2: any) => obj1.meta.order - obj2.meta.order);
  }, [section]);

  const sortedFieldList = useMemo(() => {
    return bodyParameter?.fields?.sort((obj1: any, obj2: any) => obj1.order - obj2.order);
  }, [bodyParameter, isUpdate]);

  // side effect to re-render the values of bodyparameter and selectedAnyCheck on toggle of address checkbox
  useEffect(() => {
    const updateFieldValues = (fields: any[], updateFn: (field: any) => any) => {
      return fields.map((field: any) => {
        const updatedField = updateFn(field);
        handleUpdateFieldParameter(updatedField.field_id, updatedField.field_value, updatedField.field_name);
        return updatedField;
      });
    };

    const updateBodyParameterFields = (fields: any[], updateFn: (field: any) => any) => {
      const updatedFields = updateFieldValues(fields, updateFn);
      setBodyParameter((prevState: any) => ({
        ...prevState,
        fields: updatedFields,
      }));
    };

    const updateSelectedCheckFields = (fields: any[], updateFn: (field: any) => any) => {
      const updatedFields = updateFieldValues(fields, updateFn);
      const updatedSelectedCheck = {
        ...snapshot(selectedAnyCheckStore).selectedAnyCheck,
        fields: updatedFields,
      };
      setSelectedAnyCheck(updatedSelectedCheck);
    };

    if (addressCBValue) {
      if (bodyParameter?.rel_id === 'any') {
        updateSelectedCheckFields(snapshot(selectedAnyCheckStore).selectedAnyCheck?.fields || [], copyParameterValue);
      } else {
        updateBodyParameterFields(bodyParameter?.fields || [], copyParameterValue);
      }
    } else {
      if (bodyParameter?.rel_id === 'any') {
        updateSelectedCheckFields(snapshot(selectedAnyCheckStore).selectedAnyCheck?.fields || [], clearParameterValue);
      } else {
        updateBodyParameterFields(bodyParameter?.fields || [], clearParameterValue);
      }
    }
  }, [addressCBValue]);

  // JSX Rendering
  if (isPageLoading) {
    return <CommonLoader type="checks" />;
  }
  return (
    bodyParameter && (
      <>
        {/* Header Section with back nav */}
        {/* TODO: replace en field with current language selected value */}
        <Header
          headerType={isConfirmRequired ? 'headerConfirmRequest' : 'headerBackNav'}
          headerTitle={bodyParameter?.rel_group_detail?.title?.en}
          cancelConfirm={() => setIsConfirmRequired(false)}
        />
        {/* Body Section */}
        <section className={isConfirmRequired ? 'bk-main-confirm-section' : ''}>
          {openDialog && (
            <CommonBottomDrawerHoc isOpen={openDialog} handleClose={() => setOpenDialog(false)}>
              <Stack spacing={2}>
                <Typography className="bk-headline3 text-bk_text_secondary">
                  You have not uploaded all the documents needed for verification
                </Typography>
                <MetaDetailsContainer
                  iconName="info_yellow_icon.svg"
                  noteText={`Not sharing all necessary documents might lead to delay in  your verification process`}
                  fontStyle="bK-body1"
                  bgColor="bk_tag_orange_bg"
                  textColor="bk_text_secondary"
                />
                <Grid container>
                  <Grid item xs={6} paddingRight={'4px'}>
                    <BKStyledButton fullWidth variant="text" size="large" onClick={handleConfirmSaveData}>
                      Continue anyway
                    </BKStyledButton>
                  </Grid>
                  <Grid item xs={6} paddingLeft={'4px'}>
                    <BKStyledButton
                      fullWidth
                      variant="contained"
                      size="large"
                      color="success"
                      onClick={() => setOpenDialog(false)}>
                      Back to Upload
                    </BKStyledButton>
                  </Grid>
                </Grid>
              </Stack>
            </CommonBottomDrawerHoc>
          )}
          {!isConfirmRequired && bodyParameter?.access?.mode === 'edit' ? (
            <EditableContainerHoc
              sectionList={sortedSectionList}
              relId={bodyParameter?.rel_id}
              fields={sortedFieldList}
              note={
                bodyParameter?.rel_id === 'any'
                  ? selectedAnyCheck?.collection_info?.note
                  : bodyParameter?.collection_info?.note
              }
              navigateToStep={(param) => handleStepClick(param)}
            />
          ) : (
            <ViewableContainerHoc
              mode={isConfirmRequired ? 'confirm' : 'view'}
              sectionList={sortedSectionList}
              relId={bodyParameter?.rel_id}
              fields={bodyParameter?.rel_id === 'any' ? selectedAnyCheck?.fields : sortedFieldList}
              cancelConfirm={() => setIsConfirmRequired(false)}
              navigateToStep={(param) => handleStepClick(param)}
            />
          )}
        </section>
        {/* Footer Section */}
        {footerBtnMode() === 'save' && (
          <Footer>
            <BKStyledButton
              fullWidth
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
              //disabled={btnDisable || isApiLoading}
              disabled={isApiLoading}
              onClick={handleSaveAndPreview}>
              Save & Preview
            </BKStyledButton>
          </Footer>
        )}
        {footerBtnMode() === 'confirm' && (
          <Footer>
            <BKStyledButton
              fullWidth
              variant="contained"
              size="large"
              disabled={isApiLoading}
              endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
              onClick={() => setConfirmModalOpen(true)}>
              Confirm Details
            </BKStyledButton>
          </Footer>
        )}
        {footerBtnMode() === 'otp' && (
          <Footer>
            <BKStyledButton
              fullWidth
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
              //disabled={btnDisable || isApiLoading}
              disabled={isApiLoading}
              onClick={handleGenerateOtp}>
              Generate Otp
            </BKStyledButton>
          </Footer>
        )}

        {/* MODAL START */}

        {/* If Otp is required and otp not verified enable the otp popup for otp verification */}
        {otpModalOpen && (
          <CommonBottomDrawerHoc isOpen={otpModalOpen} handleClose={() => setOtpModalOpen(false)}>
            <VerifyOtp
              headerTitle="Verify Aadhar OTP*"
              hintText="The one time password is sent to your  mobile which is linked to Aadhar. Please enter to verify Aadhar"
              btnText="Verify Aadhaar OTP"
              verificationToken={otpVerificationToken}
              closeModal={updateAndCloseModal}
            />
          </CommonBottomDrawerHoc>
        )}

        {/* Confirm Dialogue modal for confirm cta button  */}
        {confirmModalOpen && (
          <CommonBottomDrawerHoc isOpen={confirmModalOpen} handleClose={() => setConfirmModalOpen(false)}>
            <Stack spacing={2}>
              <Typography className="bk-headline3 text-bk_text_secondary">Are you sure to confirm?</Typography>
              <MetaDetailsContainer
                iconName="info_yellow_icon.svg"
                noteText="After submitting the details you will not be able to add/edit information"
                fontStyle="bK-body1"
                bgColor="bk_tag_orange_bg"
                textColor="bk_text_secondary"
              />
              <Grid container>
                <Grid item xs={6} paddingRight={'4px'}>
                  <BKStyledButton fullWidth variant="text" size="large" onClick={() => setConfirmModalOpen(false)}>
                    No, Go back
                  </BKStyledButton>
                </Grid>
                <Grid item xs={6} paddingLeft={'4px'}>
                  <BKStyledButton fullWidth variant="contained" size="large" color="success" onClick={handleConfirm}>
                    Yes, Continue
                  </BKStyledButton>
                </Grid>
              </Grid>
            </Stack>
          </CommonBottomDrawerHoc>
        )}

        {/* Modal to show when complete Menu/Group check is completed  */}
        {relGroupModalOpen && (
          <StyledRelGroupModal open={relGroupModalOpen} onClose={handleCloseRelGroupModal} width={width}>
            <section className="success-gif-container">
              <DisplayImage imageName="success_gif.gif" imageType="image" width="92px" height="92px" />
            </section>
            <Typography className="bk-headline3 text-bk_text_secondary mt-16">
              Completed {bodyParameter?.rel_group_detail?.title?.en}
            </Typography>
          </StyledRelGroupModal>
        )}
        {/* MODAL END */}
      </>
    )
  );
};
