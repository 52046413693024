import { useViewport } from '@gigin-work-space/utils';
import * as React from 'react';
import { BKStyledBottomDrawer } from '../../utils';

export interface CommonBottomDrawerHocProps {
  children: React.ReactNode;
  isOpen?: boolean;
  handleClose: () => void;
}

export const CommonBottomDrawerHoc = (props: CommonBottomDrawerHocProps) => {
  const { children, isOpen = 'false', handleClose } = props;
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpen);
  const { width } = useViewport();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(false);
    handleClose();
  };

  return (
    <BKStyledBottomDrawer
      anchor="bottom"
      open={isDrawerOpen as boolean}
      onClose={handleToggleDrawer}
      ModalProps={{
        keepMounted: true, // for better performance
      }}
      width={width}>
      {/* Drawer content */}
      {children}
    </BKStyledBottomDrawer>
  );
};
