// eslint-disable-next-line @nx/enforce-module-boundaries
import { proxy } from 'valtio';

interface SignedImage {
  value: string;
  createdAt: string;
  _id: string;
}

interface SelfDeclaration {
  self_declaration: boolean;
  data_collection_id: string;
  signature_type: string;
  signature_status: string;
  signed_image: SignedImage;
  signed_document: SignedImage;
  consent_template: string;
}

interface ISelfDeclarationType {
  self_declaration: SelfDeclaration;
  uploadTimeString: string;
}

export const SelfDeclarationStore: ISelfDeclarationType = proxy({
  self_declaration: {
    self_declaration: false,
    data_collection_id: '',
    signature_type: '',
    signature_status: '',
    signed_image: {
      value: '',
      createdAt: '',
      _id: '',
    },
    signed_document: {
      value: '',
      createdAt: '',
      _id: '',
    },
    consent_template: '',
  },
  uploadTimeString: '',
});
