import { useEffect, useState } from 'react';

export interface IUseBytesToMB {
  fileSize: number;
  fileUnit: string;
}

export const useBytesToMB = (bytes: number): IUseBytesToMB => {
  const [fileSize, setFileSize] = useState(0);
  const [fileUnit, setFileUnit] = useState('mb');

  useEffect(() => {
    const megabytesValue = bytes / 1048576;
    if (megabytesValue < 1) {
      setFileSize(Math.round(megabytesValue * 1024));
      setFileUnit('KB');
    } else {
      setFileSize(Math.round(megabytesValue));
      setFileUnit('MB');
    }
  }, [bytes]);

  return { fileSize, fileUnit };
};
