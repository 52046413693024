import { proxy } from 'valtio';
import { IOrderByRole } from '@gigin-work-space/model';

// Store to maintain orders grouped by role
export const ordersByRoleStore = proxy<{ ordersByRole: IOrderByRole[] }>({
  ordersByRole: [] as IOrderByRole[],
});

/* ------------Actions-------------*/

/*
Action to set the value of ordersByRoleStore on get api call
params: 
 - ordersByRole - list of all orders
*/
export const setOrdersByRole = (ordersByRole: IOrderByRole[]) => {
  ordersByRoleStore.ordersByRole = ordersByRole;
};

/*
Action to update the current Base Package ID of an order.
params: 
 - groupId - unique id of an order group
 - newBasePackageId - id of new selected base package
*/
export const updateBasePackageById = (groupId: string, newBasePackageId: string) => {
  const indexOfAffectedOrder = ordersByRoleStore.ordersByRole.findIndex((order) => order.group_id === groupId);
  console.log(indexOfAffectedOrder);
  console.log(newBasePackageId);

  if (indexOfAffectedOrder !== -1) {
    ordersByRoleStore.ordersByRole[indexOfAffectedOrder].package_id = newBasePackageId;
  }
  console.log(ordersByRoleStore.ordersByRole);
};
