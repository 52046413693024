import { proxy } from 'valtio';
import { IProduct } from '@gigin-work-space/model';

// Store to maintain package details for that account
export const productStore = proxy<{ products: IProduct[] | [] }>({
  products: [],
});

/* ------------Actions-------------*/

// Action to set the value of ordersByRoleStore on get api call
export const setProduct = (products: IProduct[]) => {
  productStore.products = products;
};
