import { proxy } from 'valtio';

// Store to maintain the  percentage of checks completed for progress bar in header
export const checkProgressBarStore = proxy<{ progressValue: number }>({
  progressValue: 0,
});

// Store to maintain if otp is verified or not for that check
export const checkOtpVerifiedStore = proxy<{
  isOtpVerified: boolean;
}>({
  isOtpVerified: false,
});

// Store to maintain checkbox state for currently working - employment
export const empCBUsageStore = proxy<{
  cbValue: boolean;
  targetField: string;
  cbAction: string;
}>({
  cbValue: false,
  targetField: '',
  cbAction: '',
});

// Store to maintain checkbox state for currently working - employment
export const addressCBUsageStore = proxy<{
  addressCBValue: boolean;
  allFieldsValue: any;
}>({
  addressCBValue: false,
  allFieldsValue: {},
});

/* ------------Actions-------------*/

/*
Action to set the value of progressbar store when a check is completed
params: 
 - progressValue: number
*/
export const setCheckProgressBar = (progressBarValue: number) => {
  checkProgressBarStore.progressValue = progressBarValue;
};

/*
Action to set the value of sy=tore to true once otp is verified
params: 
 - isOtpVerified: boolean
*/
export const setCheckOtpVerified = (isOtpVerified: boolean) => {
  checkOtpVerifiedStore.isOtpVerified = isOtpVerified;
};

/*
Action to set the value of employment end date checkbox on click and page load
params: 
 - cbValue: boolean
*/
export const setEmpCheckBoxUsage = (cbValue: boolean, targetField: string, cbAction: string) => {
  empCBUsageStore.cbValue = cbValue;
  empCBUsageStore.targetField = targetField;
  empCBUsageStore.cbAction = cbAction;
};

/*
Action to set the value of address checkbox on click and page load
params: 
 - cbValue: boolean
*/
export const setAddressCheckBoxUsage = (addressCBValue: boolean, allFieldsValue: any) => {
  addressCBUsageStore.addressCBValue = addressCBValue;
  addressCBUsageStore.allFieldsValue = allFieldsValue;
};
