import {
  EnumPermissionsStoreKey,
  Feature,
  PermissionAction,
  ROLE_PERMISSION_CATEGORY_KEY,
} from '@gigin-work-space/utils';
import { proxy } from 'valtio';

export interface IPermissionStoreProps {
  canReadAnalytics: boolean;
  canReadOrder: boolean;
  canReadOps: boolean;
  hasToken: boolean;
  canCreateOrder: boolean;
  canUpdateOrder: boolean;
  canDeleteOrder: boolean;
  canReadCompanyProfile: boolean;
  canCreateCompanyProfile: boolean;
  canUpdateCompanyProfile: boolean;
  canReadTeams: boolean;
  canUpdateTeams: boolean;
  canCreateTeams: boolean;
  canReadUsers: boolean;
  canCreateUsers: boolean;
  canUpdateUsers: boolean;
  canReadPackage: boolean;
  canCreatePackage: boolean;
  canUpdatePackage: boolean;
  canReadPreference: boolean;
  canCreatePreference: boolean;
  canUpdatePreference: boolean;
  canReadPreferences: boolean;
  canCreatePreferences: boolean;
  canUpdatePreferences: boolean;
  canReadAgreement: boolean;
  canReadRoles: boolean;
  canCreateRoles: boolean;
  canUpdateRoles: boolean;
  canReadReport: boolean;
  canUploadTemplate: boolean;
  canUpdateTemplate: boolean;
  canDeleteTemplate: boolean;
  canReadTemplate: boolean;
  canUploadDocument: boolean;
  canUpdateDocument: boolean;
  canDeleteDocument: boolean;
  canReadDocument: boolean;
  canReadGroupOfCompanies: boolean;
  canReadCandidate: boolean;
  canDeleteCandidate: boolean;
  canUpdateCandidate: boolean;
}
export const permissionStore = proxy<IPermissionStoreProps>({
  canReadAnalytics: false,
  canReadOrder: false,
  canReadOps: false,
  hasToken: false,
  canCreateOrder: false,
  canUpdateOrder: false,
  canDeleteOrder: false,
  canReadCompanyProfile: false,
  canCreateCompanyProfile: false,
  canUpdateCompanyProfile: false,
  canReadTeams: false,
  canUpdateTeams: false,
  canCreateTeams: false,
  canReadUsers: false,
  canCreateUsers: false,
  canUpdateUsers: false,
  canReadPackage: false,
  canCreatePackage: false,
  canUpdatePackage: false,
  canReadPreference: false,
  canCreatePreference: false,
  canUpdatePreference: false,
  canReadPreferences: false,
  canCreatePreferences: false,
  canUpdatePreferences: false,
  canReadAgreement: false,
  canReadRoles: false,
  canCreateRoles: false,
  canUpdateRoles: false,
  canReadReport: false,
  canUploadTemplate: false,
  canUpdateTemplate: false,
  canDeleteTemplate: false,
  canReadTemplate: false,
  canUploadDocument: false,
  canUpdateDocument: false,
  canDeleteDocument: false,
  canReadDocument: false,
  canReadGroupOfCompanies: false,
  canReadCandidate: false,
  canDeleteCandidate: false,
  canUpdateCandidate: false,
});

export const permissions = [
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
    feature: Feature.ORDER,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_ORDER,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
    feature: Feature.ORDER,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_ORDER,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
    feature: Feature.ORDER,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_ORDER,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
    feature: Feature.ORDER,
    action: PermissionAction.DELETE,
    permissionKey: EnumPermissionsStoreKey.CAN_DELETE_ORDER,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.REPORTS_ANALYTICS,
    feature: Feature.ANALYTIC,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_ANALYTICS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
    feature: Feature.ACCOUNT,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_COMPANY_PROFILE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
    feature: Feature.ACCOUNT,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_COMPANY_PROFILE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
    feature: Feature.ACCOUNT,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_COMPANY_PROFILE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
    feature: Feature.ACCOUNT,
    action: PermissionAction.AGREEMENT,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_AGREEMENT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.OPS_MANAGEMENT,
    feature: Feature.OPS,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_OPS,
  },

  {
    key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
    feature: Feature.TEAM,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_TEAMS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
    feature: Feature.TEAM,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_TEAMS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
    feature: Feature.TEAM,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_TEAMS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
    feature: Feature.USER,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_USERS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
    feature: Feature.USER,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_USERS,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
    feature: Feature.USER_ROLE,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_ROLES,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
    feature: Feature.USER_ROLE,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_ROLES,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
    feature: Feature.USER_ROLE,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_ROLES,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
    feature: Feature.PACKAGE,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_PACKAGE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
    feature: Feature.PACKAGE,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_PACKAGE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
    feature: Feature.PACKAGE,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_PACKAGE,
  },

  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
    feature: Feature.PREFERENCE,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_PREFERENCE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
    feature: Feature.PREFERENCE,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_CREATE_PREFERENCE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
    feature: Feature.PREFERENCE,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_PREFERENCE,
  },

  {
    key: ROLE_PERMISSION_CATEGORY_KEY.REPORTS_ANALYTICS,
    feature: Feature.BGV_REPORT,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_REPORT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DATA_MAPPING,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_TEMPLATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DATA_MAPPING,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPLOAD_TEMPLATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DATA_MAPPING,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_TEMPLATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DATA_MAPPING,
    action: PermissionAction.DELETE,
    permissionKey: EnumPermissionsStoreKey.CAN_DELETE_TEMPLATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DOCUMENT_MAPPING,
    action: PermissionAction.CREATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPLOAD_DOCUMENT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DOCUMENT_MAPPING,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_DOCUMENT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DOCUMENT_MAPPING,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_DOCUMENT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
    feature: Feature.DOCUMENT_MAPPING,
    action: PermissionAction.DELETE,
    permissionKey: EnumPermissionsStoreKey.CAN_DELETE_DOCUMENT,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.GROUP_OF_COMPANIES,
    feature: Feature.SUBSIDIARIES,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_GROUP_OF_COMPANIES,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.CANDIDATE_MANAGEMENT,
    feature: Feature.CANDIDATE,
    action: PermissionAction.READ,
    permissionKey: EnumPermissionsStoreKey.CAN_READ_CANDIDATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.CANDIDATE_MANAGEMENT,
    feature: Feature.CANDIDATE,
    action: PermissionAction.UPDATE,
    permissionKey: EnumPermissionsStoreKey.CAN_UPDATE_CANDIDATE,
  },
  {
    key: ROLE_PERMISSION_CATEGORY_KEY.CANDIDATE_MANAGEMENT,
    feature: Feature.CANDIDATE,
    action: PermissionAction.DELETE,
    permissionKey: EnumPermissionsStoreKey.CAN_DELETE_CANDIDATE,
  },
];
