import { ISection } from '@gigin-work-space/model';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledPaperCard } from '../../utils';
import DisplayImage from './display-image';

export interface SectionListProps {
  data: ISection;
}

export const SectionList = (props: SectionListProps) => {
  const { data } = props;
  const navigate = useNavigate();

  const handleClickNaviagtion = () => {
    switch (data.meta.goto.type.toLowerCase()) {
      case 'nav':
        // route to generic container with dynamic menu param
        navigate(`/collect-data/${data.meta.goto.destination}`);
        break;
      case 'popup':
        // render modal ui

        break;
      default:
        return;
    }
  };

  // TODO: When ony one field is filled, then in progress. to be added later
  return (
    <StyledPaperCard elevation={0} onClick={handleClickNaviagtion} status={data.status}>
      <div className="flex items-center gap-2">
        <div className="dashboard-icon-background">
          {data.title.en === 'Basic Detail' ? (
            <DisplayImage
              imageName="user_data_collection_icon.svg"
              imageType="icon"
              className="w-auto h-4 object-contain"
            />
          ) : (
            <DisplayImage imageName={data.image} imageType="url" className="w-auto h-5 object-contain" />
          )}
          {/* <DisplayImage imageName={data.image} imageType="url" className="w-auto h-5 object-contain" /> */}
        </div>
        {/* TODO: replace en with current selected language symbol */}
        <Stack>
          <Typography className="bk-sub-heading1 text-bk_text_primary">{data.title.en}</Typography>
          <Typography className="bK-body3 text-bk_text_secondary break-words">{data.subtitle.en}</Typography>
        </Stack>
      </div>
      <DisplayImage
        imageName={data.status?.toLowerCase() === 'completed' ? 'circle_success_filled_icon.svg' : 'arrow_right.svg'}
        imageType="icon"
        width="20px"
        height="20px"
      />
    </StyledPaperCard>
  );
};
