import { SESSION_KEYS } from '@gigin-work-space/utils';
import axios from 'axios';
import { baseUrl } from './url-constants';

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Exclude /login path from adding the Authorization header
    if (config.url !== 'data-collection/candidate-signin') {
      const auth_token = sessionStorage.getItem(SESSION_KEYS.AUTH_TOKEN);
      const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';

      if (trimmed_token) {
        config.headers['Authorization'] = `Bearer ${trimmed_token}`;
      }
    }
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptor for handling 401 Unauthorized
axiosInstance.interceptors.response.use(
  (response) => {
    // Simply return the response if it's not a 401
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem(SESSION_KEYS.AUTH_TOKEN);
      sessionStorage.removeItem(SESSION_KEYS.VERIFICATION_TOKEN);
      //session experied screen come immidiately
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
