import { ISection } from '@gigin-work-space/model';
import { empCBUsageStore } from '@gigin-work-space/store';
import { DATA_STATUS } from '@gigin-work-space/utils';
import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import { FormFieldGenerator, MetaDetailsContainer } from '../common-ui';

export interface EditableContainerHocProps {
  sectionList?: ISection[];
  relId?: string;
  fields: any[];
  note: any;
  navigateToStep: (param: string) => void;
}

export const EditableContainerHoc = (props: EditableContainerHocProps) => {
  const { sectionList, relId, fields, note, navigateToStep } = props;
  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const { cbValue, targetField } = useSnapshot(empCBUsageStore);
  const containerRef = useRef<HTMLDivElement>(null);

  // function to navigate to clicked section step if its completed
  const navigateToClickedStep = (data: any) => {
    navigateToStep(data.meta.goto.destination);
  };

  //   SIDE EFFECS
  useEffect(() => {
    const handleActiveStep = () => {
      if (sectionList) {
        const activeIndex = sectionList.findIndex((data) => data.rel_id === relId);
        if (activeIndex !== -1) {
          setCurrentActiveStep(activeIndex);
        } else {
          setCurrentActiveStep(0);
        }
      }
    };
    handleActiveStep();
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const stepWidth = 100;

      const scrollToPosition = currentActiveStep * stepWidth;

      const containerWidth = containerRef.current.offsetWidth;
      const excessWidth = Math.max(scrollToPosition - containerWidth / 2, 0);

      containerRef.current.scrollLeft = excessWidth;
    }
  }, [currentActiveStep]);

  return (
    <>
      {/* Stepper Section */}
      {sectionList && sectionList.length > 1 && (
        <section className="px-6 pt-6 w-full">
          <Box className="bk-dc-stepper-section">
            <div ref={containerRef} className="bk-stepper-scroll">
              <Stepper activeStep={currentActiveStep} alternativeLabel>
                {sectionList.map((data, id) => (
                  <Step
                    key={data.rel_id}
                    completed={data.status?.toLowerCase() === DATA_STATUS.COMPLETED}
                    disabled={data.status?.toLowerCase() === DATA_STATUS.PENDING}
                    onClick={() => navigateToClickedStep(data)}>
                    <StepLabel className="completed-step-icon">{data.title.en}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <Box className="bk-stepper-side-blur" />
          </Box>
        </section>
      )}
      {/* Dynamic Form/Field Section */}
      <div className="w-full h-[2px]"></div>
      <section className={sectionList && sectionList.length > 1 ? 'bk-main-stepper-section' : 'bk-main-section'}>
        <Grid container spacing={2} alignItems={'flex-start'} sx={{ pb: 2 }}>
          {fields &&
            fields.map((field) => (
              <Grid item xs={cbValue ? 12 : field.grid ? field.grid : 12} key={field.field_id}>
                <FormFieldGenerator params={field} />
              </Grid>
            ))}
          {note && (
            <Grid item xs={12}>
              <MetaDetailsContainer iconName="info_blue_icon.svg" noteText={note?.en} fontStyle="bk-sub-heading3" />
            </Grid>
          )}
        </Grid>
      </section>
    </>
  );
};
