// eslint-disable-next-line @nx/enforce-module-boundaries
import { IMetaProps, IMetaPropsInitial, IOrderCandidateListData } from '@gigin-work-space/model';
import { proxy } from 'valtio';

interface IOrderCandidateType {
  caseTablePage: number;
  caseTableLimit: number;
  totalCandidate: number;
  order_specific_id: string;
  range_date: string[];
  report_id: string;
  metaData: IMetaProps;
  paginationData: {
    pageIndex: number;
    pageSize: number;
  };
  candidateList: IOrderCandidateListData[];
  caseList: {
    _id: string;
    name: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    sla: string;
    packageName: string;
    currentProgess: {
      message: string;
      title: string;
      status: string;
    };
    jobRole: string;
    caseId: string;
    overallStatus: string;
    order_service_status: string;
    // initiatedBy: string;
  }[];
  searchValue: string;
  caseTableSearchValue: string;
  hasClearClicked: boolean;
  allCasesFilterApplied: boolean;
  updateConsentStatus: boolean;
  totalCases: number;
  isLoadingCandidateList: boolean;
  isLoadingConsentData: boolean;
}
export const OrderCandidateStore: IOrderCandidateType = proxy({
  allCasesFilterApplied: true,
  caseList: [],
  caseTableLimit: 20,
  range_date: ['', ''],
  caseTablePage: 1,
  totalCandidate: 0,
  order_specific_id: '',
  report_id: '',
  metaData: IMetaPropsInitial,
  paginationData: {
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  },
  candidateList: [],
  searchValue: '',
  caseTableSearchValue: '',
  hasClearClicked: false,
  updateConsentStatus: false,
  totalCases: 0,
  isLoadingCandidateList: false,
  isLoadingConsentData: false,
});

export const handlePagination = (value: any) => {
  OrderCandidateStore.paginationData = value;
};

export const updateConsentStatusValue = () => {
  OrderCandidateStore.updateConsentStatus = !OrderCandidateStore.updateConsentStatus;
};
