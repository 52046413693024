import { handleUpdateFieldParameter } from '@gigin-work-space/store';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import * as React from 'react';
import { BKStyledRadioFormLabel, BKStyledRadioGroup } from '../../../utils';

export interface BoxRadioGroupProps {
  params: any;
}

export const BoxRadioGroup = (props: BoxRadioGroupProps) => {
  const { params } = props;
  const [value, setValue] = React.useState(params.field_value.value ? params.field_value.value : '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleUpdateFieldParameter(
      params.field_id,
      {
        value: event.target.value,
      },
      params.field_name,
    );
  };

  return (
    <FormControl>
      <FormLabel id="controlled-radio-buttons-group">{params.label.en}</FormLabel>
      <BKStyledRadioGroup
        row
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}>
        {params.option.data.fields.map((data: any) => (
          <BKStyledRadioFormLabel
            key={data.value}
            value={data.value}
            control={<Radio />}
            label={data.display_text.en}
            selected={value === data.value}
          />
        ))}
      </BKStyledRadioGroup>
    </FormControl>
  );
};
