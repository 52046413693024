import { IOtpGeneralMeta } from '@gigin-work-space/model';
import { SESSION_KEYS } from '@gigin-work-space/utils';

export const getConsentContent = (formattedDateString: any) => {
  const generalDetail = JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string);
  const customConcentContent = generalDetail?.company_info?.concent_message?.replace(
    /\[Candidate Name\]/g,
    generalDetail?.candidate_info.name,
  );

  return customConcentContent
    ? `
    
    ${customConcentContent}
    


  Name: ${generalDetail?.candidate_info?.name}

  Date: ${formattedDateString}
  
  Phone number: ${generalDetail?.candidate_info?.mobile}
    
  Signature:`
    : `

  I, ${generalDetail?.candidate_info.name} the undersigned, an Indian national, hereby authorize ${generalDetail?.company_info?.name} (“Company”) and 
  its retained third parties including Gigin Technologies Private Limited (“Third Parties”) to collect, process, 
  investigate, store, use, transfer, maintain my personal data (including sensitive data), if required for 
  verification, in arch to obtain an employment verification report in connection to my application for employment.

  The employment verification report may include information regarding my character, general reputation,
  personal characteristics, Education(Authentication of acquired or pursuing Degrees/Diplomas), Employment
  history, Credit History, Court Records, including criminal verification records as permitted by law, Passport
  verification, Permanent Account Number Verification, Drug Test, Fingerprint Verification, Address
  Verification, References from professional and Personal associates as may be applicable and any other check as
  found relevant for the profile.

  I further understand and agree that the employment verification report may be obtained at any time and any
  number of times as necessary before, during or post my employment.

  I provide my consent to the company or the third parties for the processing of any my sensitive personal
  information obtained for the purpose of verification and call me in case any further information is required.

  I understand that some or all of the information I have provided in this application form or that may be
  obtained through various sources during the process of the verification of the verification will be held as
  digitized or physical records for a period and for such use as may be permitted under the applicable laws.

  I hereby authorize all previous employers, educational institutions, consumer reporting agencies and other
  persons or entities having information about me to provide such information to the company or any other third
  party or parties retained by them for the purpose.

  I understand that the continuance of the employment or the offer of employment is contingent upon the outcome
  of the background check conducted on me.

  The proof of identity enclosed and self attested for reference. A photostat, or any other copy, of this
  instrument bearing my signature shall be equally legally valid as the original.

  All the information furnished by me in the Background Verification form is true to the best of my knowledge.



  Name: ${generalDetail?.candidate_info?.name}

  Phone number: ${generalDetail?.candidate_info?.mobile}

  Date: ${formattedDateString}

  Signature:
    `;
};

export const getConsentContentWithoutDeclaration = (
  otpGeneralDetail: IOtpGeneralMeta | null,
  formattedDateString: string,
) => {
  const generalDetail = JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string);
  const customConcentContent = generalDetail?.company_info?.concent_message?.replace(
    /\[Candidate Name\]/g,
    generalDetail?.candidate_info.name,
  );
  const verification_token = sessionStorage.getItem(SESSION_KEYS.VERIFICATION_TOKEN);

  return customConcentContent
    ? `
    
    ${customConcentContent}



  Name: ${generalDetail?.candidate_info?.name}
    
  Date: ${formattedDateString}
  
  Phone number: ${generalDetail?.candidate_info?.mobile}
  
  "verification_token": ${verification_token}`
    : `
  I, ${generalDetail?.candidate_info?.name}, an Indian national, hereby authorize ${generalDetail?.company_info?.name} (“Company”) 
  and its retained third parties including Gigin Technologies Private Limited (“Third Parties”) to collect, process, investigate, 
  store, use, transfer, maintain my personal data (including sensitive data), if required for verification, in arch to obtain an 
  employment verification report in connection to my application for employment.

  The employment verification report may include information regarding my character, general reputation,
  personal characteristics, Education(Authentication of acquired or pursuing Degrees/Diplomas), Employment
  history, Credit History, Court Records, including criminal verification records as permitted by law, Passport
  verification, Permanent Account Number Verification, Drug Test, Fingerprint Verification, Address
  Verification, References from professional and Personal associates as may be applicable and any other check as
  found relevant for the profile.

  I further understand and agree that the employment verification report may be obtained at any time and any
  number of times as necessary before, during or post my employment.

  I provide my consent to the company or the third parties for the processing of any my sensitive personal
  information obtained for the purpose of verification and call me in case any further information is required.

  I understand that some or all of the information I have provided in this application form or that may be
  obtained through various sources during the process of the verification of the verification will be held as
  digitized or physical records for a period and for such use as may be permitted under the applicable laws.

  I hereby authorize all previous employers, educational institutions, consumer reporting agencies and other
  persons or entities having information about me to provide such information to the company or any other third
  party or parties retained by them for the purpose.

  I understand that the continuance of the employment or the offer of employment is contingent upon the outcome
  of the background check conducted on me.

  The proof of identity enclosed and self attested for reference. A photostat, or any other copy, of this
  instrument bearing my signature shall be equally legally valid as the original.

  All the information furnished by me in the Background Verification form is true to the best of my knowledge.


  Name: ${generalDetail?.candidate_info?.name}

  Date: ${formattedDateString}

  Phone number: ${generalDetail?.candidate_info?.mobile}

  "verification_token": ${verification_token}
    `;
};

export const CommonSection = (props: { generalDetail: any; formattedDateString: any }) => {
  const generalDetail = props.generalDetail;
  const formattedDateString = props.formattedDateString;
  const authorizationContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I, {generalDetail?.candidate_info?.name} the undersigned, an Indian national, hereby authorize{' '}
      {generalDetail?.company_info?.name} (“Company”) and its retained third parties including Gigin Technologies
      Private Limited (“Third Parties”) to collect, process, investigate, store, use, transfer, maintain my personal
      data (including sensitive data), if required for verification, in arch to obtain an employment verification report
      in connection to my application for employment.
    </p>
  );

  const reportContent = (
    <p className="bK-body2 text-bk_text_secondary">
      The employment verification report may include information regarding my character, general reputation, personal
      characteristics, Education(Authentication of acquired or pursuing Degrees/Diplomas), Employment history, Credit
      History, Court Records, including criminal verification records as permitted by law, Passport verification,
      Permanent Account Number Verification, Drug Test, Fingerprint Verification, Address Verification, References from
      professional and Personal associates as may be applicable and any other check as found relevant for the profile.
    </p>
  );

  const understandContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I further understand and agree that the employment verification report may be obtained at any time and any number
      of times as necessary before, during or post my employment.
    </p>
  );

  const consentContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I provide my consent to the company or the third parties for the processing of any my sensitive personal
      information obtained for the purpose of verification and call me in case any further information is required.
    </p>
  );

  const informationContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I understand that some or all of the information I have provided in this application form or that may be obtained
      through various sources during the process of the verification of the verification will be held as digitized or
      physical records for a period and for such use as may be permitted under the applicable laws.
    </p>
  );

  const authorizeContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I hereby authorize all previous employers, educational institutions, consumer reporting agencies and other persons
      or entities having information about me to provide such information to the company or any other third party or
      parties retained by them for the purpose.
    </p>
  );

  const continuanceContent = (
    <p className="bK-body2 text-bk_text_secondary">
      I understand that the continuance of the employment or the offer of employment is contingent upon the outcome of
      the background check conducted on me.
    </p>
  );

  const proofContent = (
    <p className="bK-body2 text-bk_text_secondary">
      The proof of identity enclosed and self attested for reference. A photostat, or any other copy, of this instrument
      bearing my signature shall be equally legally valid as the original.
    </p>
  );

  const informationFurnishedContent = (
    <p className="bK-body2 text-bk_text_secondary">
      All the information furnished by me in the Background Verification form is true to the best of my knowledge.
    </p>
  );

  return (
    <section className="bk-main-instruction-section">
      {authorizationContent}
      {reportContent}
      {understandContent}
      {consentContent}
      {informationContent}
      {authorizeContent}
      {continuanceContent}
      {proofContent}
      {informationFurnishedContent}

      <p className="bK-body2 text-bk_text_secondary mt-9">
        Name: <b>{generalDetail?.candidate_info?.name}</b>
      </p>
      <p className="bK-body2 text-bk_text_secondary">
        Phone number: <b>{generalDetail?.candidate_info?.mobile}</b>
      </p>
      <p className="bK-body2 text-bk_text_secondary">
        Date: <b>{formattedDateString}</b>
      </p>
    </section>
  );
};

export const CustomConcentSection = (props: { generalDetail: any; formattedDateString: any }) => {
  const generalDetail = props.generalDetail;
  const formattedDateString = props.formattedDateString;
  const customConcentContent = generalDetail?.company_info?.concent_message.replace(
    /\[Candidate Name\]/g,
    generalDetail?.candidate_info.name,
  );

  return (
    <section className="bk-main-instruction-section">
      <p className="bK-body1">
        <strong>{generalDetail?.company_info?.name}</strong>
      </p>
      <p className="bK-body2 text-bk_text_secondary mt-2" dangerouslySetInnerHTML={{ __html: customConcentContent }} />

      <p className="bK-body2 text-bk_text_secondary mt-9">
        Name: <b>{generalDetail?.candidate_info?.name}</b>
      </p>
      <p className="bK-body2 text-bk_text_secondary">
        Phone number: <b>{generalDetail?.candidate_info?.mobile}</b>
      </p>
      <p className="bK-body2 text-bk_text_secondary">
        Date: <b>{formattedDateString}</b>
      </p>
    </section>
  );
};
