import { proxy } from 'valtio';
import { IPackage } from '@gigin-work-space/model';

// Store to maintain package details for that account
export const packageStore = proxy<{ packages: IPackage[] | [] }>({ packages: [] });

//Store to maintain current base package
export const currentBasePackageStore = proxy<{ basePackage: IPackage[] | [] }>({ basePackage: [] });

/* ------------Actions-------------*/

// Action to set the value of ordersByRoleStore on get api call
export const setPackage = (packages: IPackage[]) => {
  packageStore.packages = packages;
};
