import { CheckoutStore } from '@gigin-work-space/store';
import { HttpStatus } from '@nestjs/common';

export interface IValidateCandidate {
  candidate: {
    initiate_checkout_id: string;
    name: { value: string; errors: boolean; error_message: string };
    email: { value: string; errors: boolean; error_message: string };
    mobile: { value: string; errors: boolean; error_message: string };
    gender: { value: string; errors: boolean; error_message: string };
    job_role: { value: string; errors: boolean; error_message: string };
  };
  is_valid_row: boolean;
}

export interface ICandidateRole {
  _id?: string;
  role_title: string;
  slug: string;
  icon: string;
  isactive?: boolean;
  job_role_id?: string;
  __v?: number;
}

export interface IDuplicateCandidate {
  name: string;
  email: string;
  mobile: string;
  role: string;
}

export interface ICandidateDetailsType {
  _id?: string;
  name: string;
  email: string;
  mobile: string;
  job_role: string;
  __v?: number;
}

export interface ImetaDataProps {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  duplicateItem: number;
  invalidItem: number;
  validItem: number;
}

export interface ICandidateProps {
  candidate: ICandidateDetailsType[];
  meta: ImetaDataProps;
}
export const MetaInitialState = {
  totalPages: 0,
  totalItems: 0,
  currentPage: 0,
  itemsPerPage: 0,
  duplicateItem: 0,
  invalidItem: 0,
  validItem: 0,
};

export const EmptyCandidate: ICandidateProps = {
  candidate: [],
  meta: MetaInitialState,
};

export interface ICandidateInterfaceTable {
  // id?: number;
  name: string;
  email: string;
  mobile: string;
  account_id: string;
  job_role: string;
  emailError: string;
  nameError: string;
  mobileError: string;
  job_roleError: string;
  isRowError: boolean;
  checkout_initiation_id: string;
  isRowNew: boolean;
}

export class CandidateAddNewRow {
  name: string;
  email: string;
  mobile: string;
  account_id: string;
  job_role: string;
  emailError: string;
  nameError: string;
  mobileError: string;
  job_roleError: string;
  isRowError: boolean;
  checkout_initiation_id: string;
  isRowNew: boolean;

  constructor() {
    this.name = '';
    this.email = '';
    this.mobile = '';
    this.account_id = '';
    this.job_role = '';
    this.emailError = '';
    this.nameError = '';
    this.mobileError = '';
    this.job_roleError = '';
    this.isRowError = false;
    this.checkout_initiation_id = CheckoutStore.checkout_initiation_id;
    this.isRowNew = true;
  }
}

export const CandidateAddNewRowObj = {
  name: '',
  email: '',
  mobile: '',
  account_id: '',
  job_role: '',
  emailError: '',
  nameError: '',
  mobileError: '',
  job_roleError: '',
  isRowError: false,
  checkout_initiation_id: '',
  isRowNew: true,
};

export interface ResponseInterface {
  success: boolean;
  message: string;
  statusCode: HttpStatus;
  data: any;
}

export interface Role {
  name: string;
}
export interface UserDetails {
  name: string;
  designation: string;
  phone: string;
  email: string;
  location: string;
  role: Role;
  isInviteSent: boolean;
}

export interface AccountDetails {
  account_name: string;
  account_type: string;
  account_role: string;
  email: string;
  address: string;
  gstin_no: string;
  isRegistered: boolean;
  createdAt: Date;
}

export interface OTPProps {
  separator: React.ReactNode;
  length: number;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  isOtpValid: boolean;
}
