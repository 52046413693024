import {
  aadhaarNumberSchema,
  addressLineSchema,
  alphanumericSchema,
  bankAccountNumberSchema,
  bankIfscSchema,
  calenderDateMonthYearSchema,
  calenderYearSchema,
  checkboxSchema,
  citySchema,
  currentlyWorkingSchema,
  dateOfBirthSchema,
  designationNameSchema,
  documentUploadSchema,
  drivingLicenceNumberSchema,
  emailSchema,
  gradeSchema,
  imageUploadSchema,
  institutionNameSchema,
  lastNameSchema,
  mobileSchema,
  nameSchema,
  numberSchema,
  pancardNumberSchema,
  passportSchema,
  pincodeSchema,
  textSchema,
  uanNumberSchema,
  voterNumberSchema,
} from './validation-schema';

export const applyRuleMap = (ruleName: string) => {
  const ruleMap: Record<string, any> = {
    name: nameSchema,
    last_name: lastNameSchema,
    email: emailSchema,
    aadhaar_number: aadhaarNumberSchema,
    pancard_number: pancardNumberSchema,
    voter_number: voterNumberSchema,
    driving_licence_number: drivingLicenceNumberSchema,
    pincode: pincodeSchema,
    city: citySchema,
    number: numberSchema,
    grade: gradeSchema,
    mobile: mobileSchema,
    alphanumeric: alphanumericSchema,
    bank_ifsc: bankIfscSchema,
    passport_number: passportSchema,
    institution_name: institutionNameSchema,
    address_line: addressLineSchema,
    designation_name: designationNameSchema,
    uan_number: uanNumberSchema,
    bank_account_number: bankAccountNumberSchema,
    currently_working: currentlyWorkingSchema,
    document_upload: documentUploadSchema,
    image_upload: imageUploadSchema,
    calender_date_month_year: calenderDateMonthYearSchema,
    dob_date_month_year: dateOfBirthSchema,
    calender_year: calenderYearSchema,
    calender_month_year: calenderYearSchema,
    text: textSchema,
    checkbox: checkboxSchema,
  };

  return ruleMap[ruleName] || null;
};
