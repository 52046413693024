import { Box, CircularProgress, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { StyledPaperCard } from '../../utils';
import { DisplayImage, Footer, Header } from '../common-ui';
export interface CommonLoaderProps {
  type: string;
}

const DashboardLoadSkeleton = () => {
  return (
    <>
      {/* Header Section */}
      <Header headerType="headerDashboardLoader" />
      {/* Main Section */}
      <section className="bk-main-section flex flex-col gap-4 pt-5">
        <Stack spacing={1}>
          <StyledPaperCard elevation={0}>
            <div className="w-full flex items-center gap-2">
              <Skeleton variant="circular" width={34} height={34} />
              <Stack width={'100%'}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </Stack>
            </div>
          </StyledPaperCard>
          <StyledPaperCard elevation={0}>
            <div className="w-full flex items-center gap-2">
              <Skeleton variant="circular" width={34} height={34} />
              <Stack width={'100%'}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </Stack>
            </div>
          </StyledPaperCard>
          <StyledPaperCard elevation={0}>
            <div className="w-full flex items-center gap-2">
              <Skeleton variant="circular" width={34} height={34} />
              <Stack width={'100%'}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </Stack>
            </div>
          </StyledPaperCard>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} marginTop="0.5rem">
          <Typography className="bK-body3 text-bk_text_secondary mr-1">Powered by </Typography>
          <DisplayImage imageName="gigin_logo.svg" imageType="image" width="32px" height="15px" />
        </Stack>
      </section>
      {/* Footer section */}
      <Footer>
        <div className="flex items-center gap-2">
          <div className="self-start">
            <DisplayImage imageName="lock_icon.svg" imageType="icon" width="10px" height="14px" />
          </div>
          <Typography className="bK-body3 text-bk_text_secondary">
            We processes your personal data in accordance with our{' '}
            <Box component={'span'} className="text-bk_tag_blue cursor-pointer">
              Privacy policy
            </Box>
          </Typography>
        </div>
      </Footer>
    </>
  );
};

const ChecksLoaderSkeleton = () => {
  return (
    <>
      {/* Header Section */}
      <Header headerType="headerChecksLoader" />
      {/* Main Section */}
      <section className="bk-main-section flex flex-col gap-4 pt-5">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={44} sx={{ borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={44} sx={{ borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={44} sx={{ borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={44} sx={{ borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height={44} sx={{ borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rounded" height={111} sx={{ borderRadius: '8px' }} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rounded" height={111} sx={{ borderRadius: '8px' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export const CommonLoader = (props: CommonLoaderProps) => {
  const { type = 'circular-loader' } = props;
  switch (type) {
    case 'checks':
      return <ChecksLoaderSkeleton />;
    case 'dashboard':
      return <DashboardLoadSkeleton />;
    default:
      return (
        <section className="w-full h-full absolute flex justify-center items-center">
          <CircularProgress />
        </section>
      );
  }
};

export default CommonLoader;
