import { SelfDeclarationStore } from '@gigin-work-space/store';
import { SelfDeclaration, SESSION_KEYS, useViewport } from '@gigin-work-space/utils';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Grid, Stack, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { axiosInstance, endpoints } from '../../services';
import { BKStyledButton, StyledRelGroupModal } from '../../utils';
import { DisplayImage, Footer, Header } from '../common-ui';
import { CommonBottomDrawerHoc } from './common-bottom-drawer-hoc';
import { CommonSection, CustomConcentSection, getConsentContent } from './consent-content';

export const SelfDeclarationEsign = () => {
  const { width } = useViewport();
  const [relGroupModalOpen, setRelGroupModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const [concentMessage, setConcentMessage] = useState<string>('');

  useEffect(() => {
    if (sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS)) {
      setConcentMessage(
        JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string).company_info?.concent_message,
      );
    }
  }, []);

  const originalDate = new Date();

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDateString = originalDate.toLocaleString('en-US', options);

  const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID);
  const generalDetail = JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string);

  const navigate = useNavigate();

  const handleClear = () => {
    if (sign != null) {
      sign.clear();
    }
  };

  const isCanvasBlank = () => {
    if (sign == null) {
      return true;
    }
    const canvas = sign.getCanvas();
    const ctx = canvas.getContext('2d');
    const imageData = ctx != null ? ctx.getImageData(0, 0, canvas.width, canvas.height).data : '';

    // Check if all pixels are transparent
    for (let i = 3; i < imageData.length; i += 4) {
      if (imageData[i] !== 0) {
        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    if (sign != null && !isCanvasBlank()) {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      const content = getConsentContent(formattedDateString);
      SelfDeclarationStore.uploadTimeString = formattedDateString;

      const fontSize = 10;
      const boldFontSize = 14;
      const pageHeight = pdf.internal.pageSize.height;

      pdf.setFontSize(boldFontSize);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Authorization Note', pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

      // Add a horizontal line
      const lineY = 12; // Adjust this value to position the line below the text
      const lineStartX = 10; // Adjust this value to control the left margin of the line
      const lineEndX = pdf.internal.pageSize.getWidth() - 10; // Adjust this value to control the right margin of the line
      pdf.line(lineStartX, lineY, lineEndX, lineY);

      pdf.setFontSize(fontSize);
      pdf.setFont('helvetica', 'normal');

      pdf.setLineWidth(0.5);
      // Split the text into lines
      const lines = pdf.splitTextToSize(content, 180);
      let y = 6;

      for (let i = 0; i < lines.length; i++) {
        // Check if line would be out of page
        if (y > pageHeight - 15) {
          pdf.addPage();
          y = 10; // Restart height on new page
        }
        pdf.text(lines[i], 12, y);
        y += 6; // Increment Y coordinate for next line
      }

      const imageURL = sign.getTrimmedCanvas().toDataURL('image/png');
      pdf.addImage(imageURL, 'PNG', 40, y, 25, 25);

      const pdfBlob = pdf.output('blob');

      const mediaData = {
        field_id: '',
        isEncrypted: true,
        filePathContext: 'bgv-check-file-upload',
        isTenantIsolated: true,
        isPrivate: true,
        shouldCompress: false,
      };

      const formData = new FormData();
      const timestamp = Date.now();
      const filename = `document_${timestamp}.pdf`;
      // body.append('file', acceptedFiles[0]);
      formData.append('field_id', '');
      formData.append('media', JSON.stringify(mediaData));
      formData.append('file', pdfBlob, filename);

      try {
        const response = await axiosInstance.post(`${endpoints.POST_FILE_S3}`, formData);

        const payloadBody = {
          selfDeclarationDetails: {
            signatureStatus: SelfDeclaration.COMPLETED,
            signedImage: {
              value: imageURL,
            },
            signedDocument: {
              value: response?.data?.data?.value,
            },
          },
        };

        await axiosInstance.patch(`${endpoints.SELF_DECLARATION_SIGN_UPDATE}/${dataCollectionId}`, payloadBody);
      } catch (error) {
        enqueueSnackbar('Error uploading sign', {
          variant: 'error',
          autoHideDuration: 2000,
        });
        return;
      }
      setRelGroupModalOpen(true);
    } else {
      enqueueSnackbar('Please sign below to continue', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleCloseRelGroupModal = () => {
    setRelGroupModalOpen(false);
    navigate('/dashboard');
  };

  return (
    <>
      <Header headerType="headerBackNav" headerTitle="Self Declaration" />
      {/* TODO: Will come up with an approach later to make it externalize */}

      {concentMessage ? (
        <CustomConcentSection generalDetail={generalDetail} formattedDateString={formattedDateString} />
      ) : (
        <CommonSection generalDetail={generalDetail} formattedDateString={formattedDateString} />
      )}

      {/* Footer continue button */}
      <Footer>
        <BKStyledButton
          fullWidth
          variant="contained"
          size="large"
          endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
          onClick={() => setConfirmModalOpen(true)}>
          Agree & Sign
        </BKStyledButton>
      </Footer>

      {confirmModalOpen && (
        <CommonBottomDrawerHoc isOpen={confirmModalOpen} handleClose={() => setConfirmModalOpen(false)}>
          <Stack spacing={2}>
            <Typography className="bk-sub-heading1 text-bk_text_secondary">
              I have read and agree to the terms mentioned
            </Typography>
            <Typography className="bk-body2 !mt-0 !text-xs">Please sign in the space below</Typography>
            <div className="eSign-box">
              <button className="eSign-clear-btn" onClick={handleClear}>
                Clear
              </button>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                ref={(data: any) => setSign(data)}
              />
            </div>
            {/* </div> */}
            <Grid className="!w-[94%]" container>
              <Grid item xs={6} paddingRight={'55px'}>
                <BKStyledButton fullWidth variant="text" size="large" onClick={() => setConfirmModalOpen(false)}>
                  Cancel
                </BKStyledButton>
              </Grid>
              <Grid item xs={6} paddingLeft={'4px'}>
                <BKStyledButton fullWidth variant="contained" size="large" color="success" onClick={handleSave}>
                  Yes, Continue
                </BKStyledButton>
              </Grid>
            </Grid>
          </Stack>
        </CommonBottomDrawerHoc>
      )}

      <StyledRelGroupModal open={relGroupModalOpen} onClose={handleCloseRelGroupModal} width={width}>
        <section className="success-gif-container">
          <DisplayImage imageName="success_gif.gif" imageType="image" width="92px" height="92px" />
        </section>
        <Typography className="bk-headline3 text-bk_text_secondary mt-16">Completed Self Declaration</Typography>
      </StyledRelGroupModal>
    </>
  );
};

export default SelfDeclarationEsign;
