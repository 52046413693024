import { COLORS, MimeTypes, SPACING } from '@gigin-work-space/utils';
import FilePresent from '@mui/icons-material/FilePresent';
import FolderZip from '@mui/icons-material/FolderZip';
import Image from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { SxProps } from '@mui/system'; // Import SxProps from MUI
import React from 'react';

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  sx?: SxProps;
}

interface FileTypeIconComponentProps {
  fileType: string;
  iconProps?: IconProps;
}

const fileTypeIconMapper: { [key: string]: JSX.Element } = {
  [MimeTypes['.pdf']]: <PictureAsPdf />,
  [MimeTypes['.jpg']]: <Image />,
  [MimeTypes['.jpeg']]: <Image />,
  [MimeTypes['.png']]: <Image />,
  [MimeTypes['.zip']]: <FolderZip />,
  [MimeTypes['.x-zip-compressed']]: <FolderZip />,
  [MimeTypes['.csv']]: <FilePresent />,
  [MimeTypes['.xls']]: <FilePresent />,
  [MimeTypes['.xlsx']]: <FilePresent />,
};

export const FileTypeIconComponent: React.FC<FileTypeIconComponentProps> = React.memo((props) => {
  const { fileType, iconProps = {} } = props;
  const icon = fileTypeIconMapper[fileType] || <InsertDriveFileIcon />;
  return React.cloneElement(icon, {
    sx: { fontSize: SPACING.xl2, color: COLORS.bk_selection_secondary, ...iconProps.sx },
    ...iconProps,
  });
});
