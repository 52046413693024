export type Language = 'en' | 'hi' | 'mr' | 'ta' | 'te' | 'ml' | 'kn' | 'bn';
import { Status } from './enums';

interface EnumTranslations {
  [key: string]: {
    [lang in Language]?: string;
  };
}

export const statusTranslations: EnumTranslations = {
  [Status.ST_ACCEPTED]: {
    en: 'Active',
    hi: 'सक्रिय',
    mr: 'सक्रिय',
    ta: 'செயலில்',
    te: 'క్రియాశీల',
    ml: 'സജീവം',
    kn: 'ಸಕ್ರಿಯ',
    bn: 'সক্রিয়',
  },
  [Status.ST_APPLD]: {
    en: 'Inactive',
    hi: 'निष्क्रिय',
    mr: 'निष्क्रिय',
    ta: 'செயலற்றது',
    te: 'సక్రియత',
    ml: 'നിഷ്ക്രിയ',
    kn: 'ನಿಷ್ಕ್ರಿಯ',
    bn: 'নিষ্ক্রিয়',
  },
  [Status.ST_ALRDY_INVITED]: {
    en: 'Pending',
    hi: 'लंबित',
    mr: 'प्रलंबित',
    ta: 'நிலுவையில்',
    te: 'పెండింగ్',
    ml: 'അനുസ്മരണം',
    kn: 'ಬಾಕಿ',
    bn: 'বিচারাধীন',
  },
};
