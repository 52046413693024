import styles from './template-banner.module.css';

/* eslint-disable-next-line */
export interface TemplateBannerProps {}

export function TemplateBanner(props: TemplateBannerProps) {
  return (
    <div className={styles['container']}>
      We added a template for delivery executive{' '}
      <span style={{ textDecoration: 'underline', marginLeft: '0.5em' }}>Remove</span>
    </div>
  );
}

export default TemplateBanner;
