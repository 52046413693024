import { MAX_SCREEN_BREAKPOINT, useViewport } from '@gigin-work-space/utils';
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { lightTheme } from '../theme';
import { BKMobileStyledContainer, RouterWidget } from '../utils';

// mitigate typing difficulties, you might want to loosen the type definition for useSnapshot in valtio
declare module 'valtio' {
  function useSnapshot<T extends object>(p: T): T;
}

export function App() {
  const theme = createTheme(lightTheme);
  const { width } = useViewport();
  const breakpoint = MAX_SCREEN_BREAKPOINT;
  return (
    <React.Suspense fallback={null}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {width <= breakpoint ? (
            <BKMobileStyledContainer>
              <RouterWidget />
            </BKMobileStyledContainer>
          ) : (
            <div className="w-full h-full flex flex-col justify-center items-center bg-bk_text_primary">
              <BKMobileStyledContainer>
                <RouterWidget />
              </BKMobileStyledContainer>
            </div>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Suspense>
  );
}

export default App;
