export interface IDialouge {
  missingRecord: boolean;
  invalidData: boolean;
  duplicateCandidate: boolean;
  additionalCheckCollapse: boolean;
  addtionalCheckExpand: boolean;
  alertUploadDoc: boolean;
  alertDocCollection: boolean;
  addCheckPopup: boolean;
  createTeam: boolean;
  inviteEmployee: boolean;
  teamList: boolean;
  editTeam: boolean;
  alertConfirmClear: boolean;
  alertSubmitData: boolean;
  dataMapping: boolean;
}

export const IDialougeInitialState = {
  missingRecord: false,
  invalidData: false,
  duplicateCandidate: false,
  additionalCheckCollapse: false,
  addtionalCheckExpand: false,
  alertUploadDoc: false,
  alertDocCollection: false,
  alertConfirmClear: false,
  addCheckPopup: false,
  createTeam: false,
  inviteEmployee: false,
  teamList: false,
  editTeam: false,
  alertSubmitData: false,
  dataMapping: false,
};
