import {
  AddressCheckboxInputField,
  BoxRadioGroup,
  DateInputField,
  EmploymentCheckboxInputField,
  SelectAnyCheckField,
  SelectInputField,
  TextInputField,
} from './molecules';
import FileUpload from './molecules/file-upload';

export interface FormFieldGeneratorProps {
  params: any;
}

// TODO: Remove switch case logic and use map logic later
export const FormFieldGenerator = (props: FormFieldGeneratorProps) => {
  const { params } = props;

  switch (params?.field_data_type?.toLowerCase()) {
    case 'text':
      return <TextInputField params={params} />;
    case 'select':
      return <SelectInputField params={params} />;
    case 'select_any_check':
      return <SelectAnyCheckField params={params.option} />;
    case 'file':
      return <FileUpload params={params} />;
    case 'zip':
      return <FileUpload params={params} />;
    case 'box_radio':
      return <BoxRadioGroup params={params} />;
    case 'employment_checkbox':
      return <EmploymentCheckboxInputField params={params} />;
    case 'address_checkbox':
      return <AddressCheckboxInputField params={params} />;
    case 'calender':
      return <DateInputField params={params} />;
    default:
      return null;
  }
};
