import { WHITELIST_URLS } from '@gigin-work-space/utils';
import ImageWithCredentials from './protected-image';

export interface DisplayImageProps {
  imageName: string;
  imageType: string;
  imageAlt?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
}

const isWhitelistedUrl = (url: string): boolean => {
  return WHITELIST_URLS.some((whitelistUrl) => url.startsWith(whitelistUrl));
};

export function DisplayImage(props: DisplayImageProps) {
  const { imageName, imageType, imageAlt = 'logo', ...rest } = props;
  const imageSource = imageType === 'url' ? imageName : `../../assets/${imageType}/${imageName}`;
  const imageUrl = imageType === 'url' ? imageName : '';

  if (imageType === 'url') {
    if (imageUrl && isWhitelistedUrl(imageUrl)) {
      return <img src={imageUrl} alt={imageAlt} {...rest} />;
    } else if (imageUrl && !isWhitelistedUrl(imageUrl)) {
      return <ImageWithCredentials imageUrl={imageUrl} imageAlt={imageAlt} {...rest} />;
    } else {
      return <div className="w-full object-contain h-28">Image not available</div>;
    }
  } else {
    return <img src={imageSource} alt={imageAlt} {...rest} />;
  }
}

export default DisplayImage;
