import { checkProgressBarStore } from '@gigin-work-space/store';
import { capitalizeString, SESSION_KEYS } from '@gigin-work-space/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Skeleton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio/react';
import { BKStyledAppbar, BKStyledIconButton } from '../../utils';
import { CheckProgressBar } from './check-progress-bar';
import DisplayImage from './display-image';

export interface HeaderProps {
  headerType?: string;
  headerTitle?: string;
  headerSubTitle?: string;
  cancelConfirm?: () => void;
}

export const Header = (props: HeaderProps) => {
  const { headerType, headerTitle, headerSubTitle, cancelConfirm } = props;

  const navigate = useNavigate();
  const generalDetail = JSON.parse(sessionStorage.getItem(SESSION_KEYS.GENERAL_DETAILS) as string);
  const { progressValue } = useSnapshot(checkProgressBarStore);

  // render normal header
  const DefaultHeader = () => {
    return (
      <AppBar position="static" color="inherit">
        <CheckProgressBar progress={progressValue} />
        <Toolbar>
          <div className="w-full flex items-center justify-between gap-1">
            <div className="w-width_80_percent flex items-center gap-2">
              <DisplayImage
                imageName={generalDetail?.company_info?.logo}
                imageType="url"
                className="w-auto h-10 cursor-pointer object-cover"
              />
              <div className="w-full flex flex-col justify-center ml-1">
                <Typography className="bk-sub-heading1 text-bk_text_primary truncate">
                  {generalDetail?.company_info?.name}
                </Typography>
                <Typography className="bK-body3 text-bk_text_secondary truncate">
                  Job role:{' '}
                  {generalDetail?.candidate_info?.job_role && capitalizeString(generalDetail?.candidate_info?.job_role)}
                  {/* {companyInfo.description.tagline} */}
                </Typography>
              </div>
            </div>
            {/* TODO: Will enable later when multi language will come into picture */}
            {/* <BKStyledIconButton size="large" edge="end" color="inherit" aria-label="language change button">
              <DisplayImage imageName="language_icon.svg" imageType="icon" width="19px" height="19px" />
            </BKStyledIconButton> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  // render header with back nav
  const HeaderWithBackNav = () => {
    return (
      <AppBar position="static" color="inherit">
        <CheckProgressBar progress={progressValue} />
        <Toolbar>
          <div className="w-full flex items-center justify-between gap-1">
            <div className="w-width_80_percent flex items-center">
              <BKStyledIconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="company logo"
                onClick={() => navigate('/dashboard')}>
                <ArrowBackIcon />
              </BKStyledIconButton>
              <div className="w-full flex flex-col justify-center">
                <Typography className="bk-sub-heading1 text-bk_text_primary truncate">{headerTitle}</Typography>
              </div>
            </div>
            {/* TODO: Will enable later when multi language will come into picture */}
            {/* <BKStyledIconButton size="large" edge="end" color="inherit" aria-label="language change button">
              <DisplayImage imageName="language_icon.svg" imageType="icon" width="19px" height="19px" />
            </BKStyledIconButton> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  // render confirm request  header
  const ConfirmRequestHeader = () => {
    return (
      <BKStyledAppbar position="static">
        <Toolbar>
          <div className="w-full flex items-center gap-1">
            <BKStyledIconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="company logo"
              onClick={cancelConfirm}>
              <CloseOutlinedIcon />
            </BKStyledIconButton>
            <Typography className="bk-sub-heading1 text-bk_bg_primary truncate">Confirm {headerTitle}</Typography>
          </div>
        </Toolbar>
      </BKStyledAppbar>
    );
  };

  const HeaderDashboardLoadSkeleton = () => {
    return (
      <AppBar position="static" color="inherit">
        <Toolbar>
          <div className="w-full flex items-center justify-between gap-1">
            <div className="w-width_80_percent flex items-center">
              <Skeleton variant="rounded" width={42} height={42} sx={{ borderRadius: '8px' }} />
              <div className="w-full flex flex-col justify-center ml-1">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
              </div>
            </div>
            {/* TODO: Will enable later when multi language will come into picture */}
            {/* <Skeleton variant="rounded" width={32} height={32} sx={{ borderRadius: '8px' }}/> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  const HeaderChecksLoadSkeleton = () => {
    return (
      <AppBar position="static" color="inherit">
        <Toolbar>
          <div className="w-full flex items-center justify-between gap-1">
            <div className="w-width_80_percent flex items-center">
              <Skeleton variant="rounded" width={42} height={42} sx={{ borderRadius: '8px' }} />
              <div className="w-full flex flex-col justify-center ml-1">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </div>
            </div>
            {/* TODO: Will enable later when multi language will come into picture */}
            {/* <Skeleton variant="rounded" width={32} height={32} sx={{ borderRadius: '8px' }}/> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  switch (headerType) {
    case 'headerDashboardLoader':
      return (
        <Box sx={{ flexGrow: 1 }}>
          <HeaderDashboardLoadSkeleton />
        </Box>
      );
    case 'headerChecksLoader':
      return (
        <Box sx={{ flexGrow: 1 }}>
          <HeaderChecksLoadSkeleton />
        </Box>
      );
    case 'headerBackNav':
      return (
        <Box sx={{ flexGrow: 1 }}>
          <HeaderWithBackNav />
        </Box>
      );
    case 'headerConfirmRequest':
      return (
        <Box sx={{ flexGrow: 1 }}>
          <ConfirmRequestHeader />
        </Box>
      );
    default:
      return <Box sx={{ flexGrow: 1 }}>{generalDetail?.company_info && <DefaultHeader />}</Box>;
  }
};

export default Header;
