import { IOtpGeneralMeta } from '@gigin-work-space/model';
import { proxy } from 'valtio';

// Store to maintain otp sent general details
export const otpGeneralDetailStore = proxy<{
  otpGeneralDetail: IOtpGeneralMeta | null;
}>({
  otpGeneralDetail: {} as IOtpGeneralMeta,
});

/* ------------Actions-------------*/

/*
Action to set the value of otp related generic details like, sendto, masked value and verification token
params: 
 - otpGeneralDetail - value of otp related generic details like, sendto, masked value and verification token
*/
export const setOtpGeneralDetails = (otpGeneralDetail: IOtpGeneralMeta | null) => {
  otpGeneralDetailStore.otpGeneralDetail = otpGeneralDetail;
};
