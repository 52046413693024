import { OtpTimer } from '@gigin-work-space/common-ui';
import { SESSION_KEYS } from '@gigin-work-space/utils';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { axiosInstance, endpoints } from '../../services';
import { BKStyledButton, StyledOTPContainerResend, StyledOTPInput } from '../../utils';

export interface VerifyOtpProps {
  headerTitle?: string;
  hintText?: string;
  btnText?: string;
  verificationToken: string;
  closeModal: () => void;
}

export const VerifyOtp = (props: VerifyOtpProps) => {
  const { headerTitle, hintText, btnText, verificationToken, closeModal } = props;
  const theme = useTheme();
  const [otpValue, setotpValue] = useState('');
  const [isOtpCorrect, setIsOtpCorrect] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [otpToken, setOtpToken] = useState(verificationToken);

  const { enqueueSnackbar } = useSnackbar();
  const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID);

  // function to handle otp input change
  const handleOtpChange = (value: string) => {
    if (value.length > 6) return;
    setotpValue(value);
    if (value.length === 6) {
      setBtnDisable(false);
      return;
    }
    setBtnDisable(true);
  };

  // function to submit and verify otp
  const handleOtpSubmit = async () => {
    setBtnDisable(true);
    const body = {
      dataCollectionId: dataCollectionId,
      code: otpValue,
      verificationToken: otpToken,
    };
    try {
      const response = await axiosInstance.post(`${endpoints.POST_AADHAAR_OTP_VERIFICATION}`, body);
      if (response.data.data.status) {
        setIsOtpCorrect(true);
        enqueueSnackbar('Otp Verified Successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setTimeout(() => {
          closeModal();
        }, 2000);
        return;
      }
      throw new Error('Invalid Otp');
    } catch (error) {
      setIsOtpCorrect(false);
    } finally {
      setBtnDisable(false);
    }
  };

  // function to resend otp
  const handleResendOtp = async () => {
    // handle unnecessary resend clicks
    if (isApiLoading) return;

    try {
      setotpValue('');
      setIsOtpCorrect(true);
      setIsApiLoading(true);
      const body = { data_collection_id: dataCollectionId };
      const response = await axiosInstance.post(`${endpoints.GET_AADHAAR_OTP}`, body);
      if (response.data.success) {
        setOtpToken(response.data.verification_token);
        return;
      }
      throw new Error('Failed to resend OTP');
    } catch (error) {
      enqueueSnackbar('Failed to resend OTP', { variant: 'error', autoHideDuration: 2000 });
    } finally {
      setIsApiLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      {/* header title section*/}
      {headerTitle && <Typography className="bk-headline3 text-bk_text_primary">{headerTitle}</Typography>}
      {/* otp input section */}
      <div className="w-full flex flex-col justify-start gap-2">
        <Typography className="bk-sub-heading2 text-bk_text_primary">Please enter OTP</Typography>
        <StyledOTPInput
          theme={theme}
          isOtpCorrect={isOtpCorrect}
          value={otpValue}
          numInputs={6}
          autoComplete="true"
          shouldAutoFocus={true}
          isInputNum={true}
          onChange={handleOtpChange}
        />
        {!isOtpCorrect && (
          <Box component="span" className="bk-sub-heading2 text-bk_alert_failure">
            {'Otp entered is incorrect'}
          </Box>
        )}
      </div>
      {/* hint text section */}
      {hintText && <Typography className="bK-hint-text text-bk_bg_dark">{hintText}</Typography>}
      {/* resend otp section */}
      {/* <OTPValidatePage /> */}
      <StyledOTPContainerResend>
        <OtpTimer
          minutes={1}
          seconds={0}
          text={"Didn't get a code? Resend OTP in "}
          resendText={'Resend otp'}
          resendOtp={handleResendOtp}
          otpValid={isOtpCorrect}
        />
      </StyledOTPContainerResend>
      {/* CTA Button section */}
      <BKStyledButton
        fullWidth
        variant="contained"
        className="bg-bk_action_primary"
        size="large"
        endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
        onClick={handleOtpSubmit}
        disabled={btnDisable}>
        {btnText}
      </BKStyledButton>
    </Stack>
  );
};
