import { proxy } from 'valtio';

// Store to maintain otp sent general details
export const selectedAnyCheckStore = proxy<{
  selectedAnyCheck: any;
}>({
  selectedAnyCheck: {},
});

/* ------------Actions-------------*/

/*
Action to set the parameter values of check selected from Dropdown - any check
params: 
 - selectedAnyCheck - parameter values of check selected from Dropdown - any check
*/
export const setSelectedAnyCheck = (selectedAnyCheck: any) => {
  selectedAnyCheckStore.selectedAnyCheck = selectedAnyCheck;
};
