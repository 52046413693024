import { BKBody1, BKSubHeading2, COLORS, RADIUS, SPACING } from '@gigin-work-space/utils';
import {
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  FormControl,
  FormGroup,
  Grid,
  LinearProgress,
  Paper,
  Select,
  StepConnector,
  Stepper,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  linearProgressClasses,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

export const BKStyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: COLORS.bk_action_primary, // change the label color when the TextField is focused
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    // change the underline color when the TextField is hovered
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiInput-underline:before': {
    // borderBottomColor: 'gray', // change the default underline color
  },
  '& .MuiInput-underline.Mui-error:before': {
    borderBottomColor: 'red', // change the underline color when there's an error
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline 	.MuiSvgIcon-root': {
    borderColor: COLORS.bk_action_primary, // change the border color when the TextField is focused
    color: COLORS.bk_action_primary,
  },
  '& .MuiOutlinedInput-root:hover': {
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.bk_action_primary, // change the border color when the TextField is hovered
    color: COLORS.bk_action_primary,
  },
  '& .MuiOutlinedInput-root .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'red', // change the border color when there's an error
  },

  '& .MuiOutlinedInput-root:hover .MuiSvgIcon-root': {
    color: COLORS.bk_action_primary,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover .MuiSvgIcon-root': {
      color: COLORS.bk_action_primary,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: COLORS.bk_action_primary,
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: COLORS.bk_tag_gray_bg, // Set the background color
    border: 0,
    paddingLeft: '12px',
  },
  '.MuiAutocomplete-hasClearIcon .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: 0,
  },
}));

export const BKStyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select:hover': {
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiSelect-select.Mui-focused': {
    backgroundColor: COLORS.bk_bg_primary,
  },
  '& .MuiSelect-select:hover:not(.Mui-disabled):before': {
    backgroundColor: COLORS.bk_tag_gray_bg,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root:hover': {
    backgroundColor: COLORS.bk_bg_primary,
  },
}));

export const BKStyledStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    height: 'auto',
  },
  '& .MuiStepLabel-iconContainer.Mui-active': {
    color: COLORS.bk_action_primary,
  },
  '& .MuiStepLabel-iconContainer.Mui-disabled': {
    color: COLORS.bk_text_secondary,
  },
  '& .MuiStepLabel-vertical': {
    columnGap: '28px',
  },
}));

export const BKStyledChip = styled(Chip)(({ theme }) => ({
  padding: '12px 4px',
  color: COLORS.bk_text_primary,
  background: COLORS.bk_bg_primary,
  border: `1px solid ${COLORS.bk_stroke_secondary}`,
  borderRadius: '8px',
  '&:hover': {
    color: COLORS.bk_action_primary,
    backgroundColor: COLORS.bk_tag_action_bg,
    border: `1px solid ${COLORS.bk_action_primary}`,
    '& .MuiChip-deleteIcon': {
      color: COLORS.bk_action_primary,
    },
  },
  // '& .MuiChip-filledPrimary': {
  //   color: COLORS.bk_action_primary,
  //   backgroundColor: COLORS.bk_tag_action_bg,
  //   border: `1px solid ${COLORS.bk_action_primary}`,
  //   '& .MuiChip-deleteIcon': {
  //     color: COLORS.bk_action_primary,
  //   },
  // },

  // '& .MuiChip-deletableColorPrimary, .MuiChip-deletableColorPrimary .MuiChip-deleteIcon': {
  //   color: COLORS.bk_action_primary,
  //   backgroundColor: COLORS.bk_tag_action_bg,
  //   border: `1px solid ${COLORS.bk_action_primary}`,
  // },
  // '& .MuiChip-colorPrimary': {
  // color: COLORS.bk_action_primary,
  // backgroundColor: COLORS.bk_tag_action_bg,
  // border: `1px solid ${COLORS.bk_action_primary}`,
  //   '& .MuiChip-deleteIcon': {
  //     color: COLORS.bk_action_primary,
  //   },
  // },
}));

//custom tabs with No text decoration
export const BKStyledTabs = styled(Tabs)({
  '& .MuiTab-root': {
    textTransform: 'none',
    padding: '10px 2px',
    // borderRight: '1px solid #ccc', // Pipe symbol styling
    ...BKBody1,
    // TODO: | line need to add
    // '&::after': {
    //   display: 'flex',
    //   marginRight: '5px',
    //   marginLeft: '5px',
    //   flexDirection: 'column',
    //   content: '" | "',
    //   // position: 'absolute',
    //   // top: '50%',
    //   // right: 0,
    //   // transform: 'translateY(-50%)',
    //   // color: '#ccc', // Adjust the color as needed
    // },
    '&:hover': {
      backgroundColor: COLORS.bk_tag_action_bg,
      borderRadius: '8px',
    },
  },
  '& .MuiTab-root.Mui-selected': {
    textTransform: 'none',
    ...BKSubHeading2,
  },

  '& .MuiTabs-flexContainer': {
    '& .MuiTab-root': {
      marginRight: '24px', // Adjust the gap between tabs
    },
    '& .tab-divider': {
      margin: '0 32px', // Adjust the gap around the divider
    },
  },
});
//custom tabs with No text decoration
export const BKStyledEBTabs = styled(Tabs)({
  '& .MuiTab-root': {
    textTransform: 'none',
    ...BKSubHeading2,
    // TODO: | line need to add
    '&:hover': {
      backgroundColor: COLORS.bk_tag_action_bg,
      borderRadius: '8px',
    },
  },
  '& .MuiTab-root.Mui-selected': {
    textTransform: 'none',
    ...BKSubHeading2,
  },

  '& .MuiTabs-flexContainer': {
    '& .MuiTab-root': {},
    '& .tab-divider': {},
  },
});

//linear progress bar for bgv completion progress
export const BKStyledOrdersLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === 'light' ? COLORS.bk_alert_success : '#308fe8',
  },
}));
export const BkStyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const BkStyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: COLORS.bk_alert_new,
  },
}));

export const BkStyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  background: COLORS.bk_tag_gray_bg,
  '& .MuiLinearProgress-bar': {
    background: COLORS.bk_tag_blue, // color change of linear progress change
  },
}));

// bgv page stepper
export const BKBgvStyledStepper = styled(Stepper)(({ theme }) => ({
  cursor: 'pointer',
  '& .MuiStepConnector-line': {
    height: 'auto',
  },
  '& .MuiStepLabel-iconContainer.Mui-active': {
    color: COLORS.bk_bg_primary,
  },
  '& .MuiStepLabel-iconContainer.Mui-disabled': {
    color: COLORS.bk_text_secondary,
  },
  // '& .MuiStepLabel-iconContainer.Mui-completed': {
  //   color: COLORS.bk_text_secondary,
  // },
  '& .MuiStepLabel-vertical': {
    columnGap: '4px',
  },
  '&.MuiStepper-root .MuiStepLabel-iconContainer.Mui-active': {
    background: COLORS.bk_secondary_active,
    padding: '10px',
    borderRadius: '50%',
  },
  '&.MuiStepper-root .MuiStepLabel-iconContainer.Mui-disabled': {
    background: COLORS.bk_tag_gray_bg,
    padding: '10px',
    borderRadius: '50%',
    color: COLORS.bk_secondary_disabled,
  },
  '&.MuiStepper-root .MuiStepLabel-iconContainer.Mui-completed': {
    background: COLORS.bk_tag_gray_bg,
    padding: '10px',
    borderRadius: '50%',
    color: COLORS.bk_secondary_disabled,
  },

  '& .MuiStepConnector-root': {
    marginLeft: '20px',
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: COLORS.bk_text_secondary,
  },
}));
// STYLED ACCORDIAN START
export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: '16px',
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    padding: 0,
    margin: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  }),
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  transition: 'height 0.3s ease-in-out',
}));
// STYLED ACCORDIAN END

// Style paper with grey BG
export const BkStyledGreyPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  background: COLORS.bk_tag_gray_bg,
  borderRadius: '8px',
  padding: '0.75rem',
}));

// styled Select dropdown with grey bg
export const BKStyledGreySelect = styled(Select)(({ theme }) => ({
  '& .MuiMenuItem-root.Mui-selected': {
    backgroundColor: COLORS.bk_tag_blue_bg,
  },

  '& .MuiSelect-select:hover': {
    backgroundColor: COLORS.bk_tag_gray_bg,
  },
  '& .MuiSelect-select.Mui-focused': {
    backgroundColor: COLORS.bk_tag_gray_bg,
  },
  '& .MuiSelect-select:hover:not(.Mui-disabled):before': {
    backgroundColor: COLORS.bk_tag_gray_bg,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root:hover': {
    backgroundColor: COLORS.bk_tag_gray_bg,
  },
}));

//Grid with no default padding
export const BkStyledNoPaddingGrid = styled(Grid)(({ theme }) => ({
  padding: 0,
}));
export const BkStyledCustomGrid = styled(Grid)(({ theme }) => ({}));

// Container footer fixed at bottom having CTA Actions
export const BkStyledFooterPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: 2,
  width: '60vw',
}));

export const BKStyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': { textTransform: 'none', fontFamily: 'Figtree' },
  '&.MuiButton-root:focus, &.MuiButton-root:active, &.MuiButton-root:hover': {
    outline: 'none', // Remove focus outline
    boxShadow: 'none', // Remove box shadow
  },
}));

export const BKStyledTextFieldTableCell = styled(TextField)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: '0px 8px 0px 8px',
  },
  variant: 'outlined',
  '& fieldset': { border: 'none' },
  InputProps: { disableUnderline: true },
}));

export const BKFilterButton = styled(ToggleButton)(({ theme }) => ({
  color: COLORS.bk_text_primary,
  display: 'flex',
  justifyContent: 'flex-start',
  borderRadius: '2px',
  textTransform: 'none',

  '&.MuiToggleButton-root': {
    background: COLORS.bk_bg_primary,
    border: `1px solid ${COLORS.bk_stroke_secondary}`,
    '&.Mui-selected': {
      color: COLORS.bk_action_primary,
      backgroundColor: `${COLORS.bk_tag_action_bg} !important`,
      border: `1px solid ${COLORS.bk_action_primary} !important`,
    },
  },

  '&:hover': {
    color: COLORS.bk_action_primary,
    backgroundColor: COLORS.bk_tag_action_bg,
  },
}));

// styled vertical tabs - company setting
export const BKStyledVerticalTab = styled(Tabs)(({ theme }) => ({
  padding: '20px 8px 8px 16px',
  borderRight: `2px solid ${COLORS.bk_stroke_secondary}`,
  width: '216px',
  height: '90%',
  overflowY: 'auto',
  position: 'fixed',
  top: '14%',
  backgroundColor: '#fff',
  outline: 0,
  zIndex: 9,

  '& .MuiButtonBase-root': {
    textTransform: 'none',
    padding: '10px',
    alignItems: 'flex-start',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    display: 'none',
  },
  '& .Mui-selected': {
    backgroundColor: COLORS.bk_tag_action_bg,
    borderRadius: '8px',
  },
}));

// styled select to show whole group permissions - company setting
export const BKStyledStandardSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    fontFamily: 'Figtree',
  },
  '& .MuiSelect-icon': {
    color: 'inherit',
  },
}));

// styled FormGroup for checkbox and label - company setting
export const BKStyledFormGroup = styled(FormGroup)(({ theme }) => ({
  margin: '0px !important',
  width: 'fit-content',
  '& .MuiTypography-root': {
    fontStyle: 'normal',
    fontWeight: '400px',
    fontSize: '14px',
    lineHeight: '15px',
    color: COLORS.bk_text_secondary,
    fontFamily: 'Figtree',
  },
}));

// styled Table cell with custom padding
export const BKStyledTableCell = styled(TableCell)(({ theme }) => ({
  '& .MuiTableCell-head': {
    padding: '8px !important',
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

// Styled Select Group - small size
export const BKStyledSmallSelectGroup = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    transform: 'translate(14px, 9px) scale(1)',
  },
  '& .MuiFormLabel-root.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
}));

export const BKStyledLandingTextField = styled(TextField)(({ isTop }: { isTop: boolean }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      color: isTop ? 'white' : '',
      borderColor: isTop ? 'white' : '',
    },
    '&:hover fieldset': {
      borderColor: isTop ? 'white' : '',
    },
  },
}));

// MuiTableCell-root
export const BKStyledEBTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px !important',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: 'Figtree',
}));

export const BKSelectedButton = styled(Button)<{ isClicked?: boolean }>(({ isClicked }) => ({
  backgroundColor: isClicked ? '#F0F0FF' : '#FFFFFF',
  color: isClicked ? '#6200EA' : '#707070',
  border: isClicked ? '1px solid #6200EA' : '1px solid #E0E0E0',
  borderRadius: '20px',
  textTransform: 'none',
  fontWeight: 500,
  padding: '6px 16px',
  minWidth: 150,
  '&:hover': {
    backgroundColor: isClicked ? '#F0F0FF' : '#FFFFFF',
    borderColor: isClicked ? '#6200EA' : '#E0E0E0',
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    padding: SPACING.xl2,
    borderRadius: RADIUS.xl2,
    width: '60%',
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    padding: 0,
  },
}));

export const QRCodeBox = styled(Box)(() => ({
  width: '180px',
  height: '180px',
  backgroundColor: COLORS.bk_tag_action_bg,
  borderRadius: RADIUS.lg,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const StyledList = styled('ol')(({ theme }) => ({
  listStyle: 'none',
  counterReset: 'item',
  paddingLeft: 0,
  '& li': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4),
    '&::before': {
      content: 'counter(item)',
      counterIncrement: 'item',
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      backgroundColor: COLORS.bk_stroke_secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-start',
      marginRight: theme.spacing(2),
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

export const BkStyledFormTextField = styled(TextField)<{ maxWidth?: string }>(({ maxWidth }) => ({
  height: SPACING.xxl3,
  borderRadius: RADIUS.xs2,
  maxWidth: maxWidth || '100%',
  '& .MuiInputBase-root': {
    height: '100%',
    borderRadius: 'inherit',
  },
}));

export const StyledAuthenticationBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    top: '10vh',
    left: '7vw',
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: '12vh',
    left: '8vw',
  },
  [theme.breakpoints.up('xl')]: {
    position: 'absolute',
    top: '15vh',
    left: '10vw',
  },
}));

export const StyledAuthenticationImageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    left: '0',
    width: '50vw',
    height: '50vh',
  },
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    bottom: '0',
    left: '6vw',
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    bottom: '0',
    left: '7vw',
  },
  [theme.breakpoints.up('xl')]: {
    position: 'absolute',
    bottom: '0',
    left: '10vw',
  },
}));

export const StyledAuthenticationPaper = styled(Box)(({ theme }) => ({
  width: '90%',
  [theme.breakpoints.down('lg')]: {
    width: '372px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '496px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '600px',
  },
  backgroundColor: COLORS.bk_bg_primary,
  borderRadius: RADIUS.xl2,
  padding: SPACING.xl3,
}));

interface ICustomIconProps {
  completed: boolean;
  active: boolean;
  default: boolean;
}

export const CustomStepIconRoot = styled('div')<ICustomIconProps>(({ completed, active, default: isDefault }) => ({
  backgroundColor: completed ? '#6200ea' : '#eaeaf0',
  zIndex: 1,
  color: active || completed ? '#fff' : '#6200ea',
  width: 20,
  height: 20,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(active && {
    backgroundColor: '#6200ea',
  }),
  ...(isDefault && {
    border: '1px solid #A083DD',
    backgroundColor: '#fff',
  }),
}));

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLORS.bk_text_secondary2,
    borderTopWidth: 2,
    width: '40px',
    display: 'block',
    margin: '0 auto',
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.bk_text_secondary2,
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.bk_text_secondary2, // Active step color
    },
  },
}));

export const BKStyledField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: '8px 12px',
    outline: 'none',
    border: 0,
    '&:hover': {
      background: COLORS.bk_bg_primary,
    },
    '&:focus': {}, // You can remove this if not needed
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    fontFamily: 'Figtree',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  minWidth: '272px',
}));
