import { handleUpdateFieldParameter, SelfDeclarationStore } from '@gigin-work-space/store';
import {
  IMAGE_AND_FILE_ACCEPTED,
  MAX_FILE_SIZE,
  SelfDeclaration,
  SESSION_KEYS,
  useBytesToMB,
  useViewport,
} from '@gigin-work-space/utils';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, endpoints } from '../../services';
import { BKStyledButton, StyledPaperFileUpload, StyledRelGroupModal } from '../../utils';
import { DisplayImage, FilePreview, Footer, Header, MetaDetailsContainer } from '../common-ui';
import { CommonBottomDrawerHoc } from '../components';
import { getConsentContent } from './consent-content';

const initialFileValue = {
  value: '',
  data_type: 'file',
  media_id: '',
  file_name: '',
  file_size: '',
};

export default function SelfDeclarationManual() {
  const { width } = useViewport();
  const [relGroupModalOpen, setRelGroupModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enablePreview, setEnablePreview] = useState(false);
  const [fileDetails, setFileDetails] = useState(initialFileValue);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { t } = useTranslation();

  const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID);

  const field = {
    field_id: dataCollectionId ? dataCollectionId : '',
    label: {
      en: 'Upload Consent template',
      hi: 'Upload Consent template Hindi',
    },
    field_name: 'self_declaration_signed_document',
  };

  const originalDate = new Date();

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDateString = originalDate.toLocaleString('en-US', options);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noDrag: true,
    accept: IMAGE_AND_FILE_ACCEPTED,
    multiple: false,
  });

  const { fileSize, fileUnit } = useBytesToMB(MAX_FILE_SIZE);

  // Function to clear file on click of cross
  const handleClearFile = () => {
    setEnablePreview(false);
    setFileDetails(initialFileValue);
    handleUpdateFieldParameter(field.field_id, initialFileValue, field.field_name);
  };

  // function to upload file on S3 and return image url to parent
  const uploadFileOnS3 = useCallback(async () => {
    const acceptedFile = acceptedFiles[0];
    const ALLOWED_MIME_TYPES = "['image/jpeg', 'image/png', 'application/pdf']";
    if (!ALLOWED_MIME_TYPES.includes(acceptedFile.type)) {
      enqueueSnackbar(`Please upload a file in PNG, JPEG, JPG, or PDF format only.`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles[0].size > MAX_FILE_SIZE) {
      enqueueSnackbar(`Maximum file size is ${fileSize}${fileUnit} only`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      setLoading(true);
      // TODO: for now type and typeid is dummy, will change later
      const mediaData = {
        field_id: field?.field_id || '',
        isEncrypted: true,
        filePathContext: 'bgv-check-file-upload',
        isTenantIsolated: true,
        isPrivate: true,
        shouldCompress: false,
      };

      const body = new FormData();
      body.append('file', acceptedFiles[0]);
      body.append('field_id', field.field_id);
      body.append('media', JSON.stringify(mediaData));

      const response = await axiosInstance.post(`${endpoints.POST_FILE_S3}`, body);

      // TODO: Handle success & error scenarios
      if (response?.status === 201) {
        setFileDetails(response.data.data);
        handleUpdateFieldParameter(field.field_id, response.data.data, field.field_name);
        setEnablePreview(true);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false after upload (success or failure)
    }
  }, [acceptedFiles]);

  // Side Effect
  useEffect(() => {
    if (acceptedFiles.length) {
      uploadFileOnS3();
    }
  }, [uploadFileOnS3]);

  // useMemo(() => {
  //   setEnablePreview(!field?.field_value?.file_name ? false : true);
  //   setFileDetails(field?.field_value?.value ? field?.field_value : initialFileValue);
  // }, [field]);

  const navigate = useNavigate();

  const handleCloseRelGroupModal = () => {
    setRelGroupModalOpen(false);
    navigate('/dashboard');
  };

  const handleConfirm = async () => {
    if (!enablePreview) {
      enqueueSnackbar(`Please upload the signed consent document to continue.`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      const payloadBody = {
        selfDeclarationDetails: {
          signatureStatus: SelfDeclaration.COMPLETED,
          signedDocument: {
            value: fileDetails.value,
          },
        },
      };
      try {
        await axiosInstance.patch(`${endpoints.SELF_DECLARATION_SIGN_UPDATE}/${dataCollectionId}`, payloadBody);
      } catch (error) {
        enqueueSnackbar('Error uploading document', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
      setRelGroupModalOpen(true);
    }
  };

  const handleDownload = () => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const content = getConsentContent(formattedDateString);
    SelfDeclarationStore.uploadTimeString = formattedDateString;

    const fontSize = 10;
    const boldFontSize = 14;
    const pageHeight = pdf.internal.pageSize.height;

    pdf.setFontSize(boldFontSize);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Authorization Note', pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

    // Add a horizontal line
    const lineY = 12; // Adjust this value to position the line below the text
    const lineStartX = 10; // Adjust this value to control the left margin of the line
    const lineEndX = pdf.internal.pageSize.getWidth() - 10; // Adjust this value to control the right margin of the line
    pdf.line(lineStartX, lineY, lineEndX, lineY);

    pdf.setFontSize(fontSize);
    pdf.setFont('helvetica', 'normal');

    pdf.setLineWidth(0.5);
    // Split the text into lines
    const lines = pdf.splitTextToSize(content, 180);
    let y = 6;

    for (let i = 0; i < lines.length; i++) {
      // Check if line would be out of page
      if (y > pageHeight - 15) {
        pdf.addPage();
        y = 10; // Restart height on new page
      }
      pdf.text(lines[i], 12, y);
      y += 6; // Increment Y coordinate for next line
    }

    pdf.save('ConsentForm.pdf');
  };

  return (
    <>
      <Header headerType="headerBackNav" headerTitle="Self Declaration" />
      <div className="flex flex-col items-center gap-1 py-4 px-3 rounded">
        <Grid item xs={12}>
          <MetaDetailsContainer
            iconName="info_blue_icon.svg"
            noteText="Please download the consent form below, fill in the required details manually and upload the same."
            fontStyle="bk-sub-heading3"
          />
        </Grid>
        <button className="consent-btn" onClick={handleDownload}>
          <DisplayImage imageName="download_consent_icon.svg" imageType="icon" width="32px" height="15px" />
          <Typography>Download Consent template</Typography>
        </button>

        <Grid className="mt-4" container spacing={3}>
          <Grid item xs={12} key={field.field_id}>
            {!enablePreview ? (
              <StyledPaperFileUpload className="consent-file-upload" id={field.field_id} elevation={0}>
                {loading ? (
                  <div className="w-full flex justify-center items-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <Stack
                    spacing={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                    {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Stack spacing={1} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                      <DisplayImage imageName="cloud_upload_icon.svg" imageType="icon" width="20px" height="20px" />
                      <Typography className="bk-sub-heading3 text-bk_text_secondary text-center">
                        Upload Consent template
                      </Typography>
                    </Stack>
                    <Typography className="bK-body3 text-bk_text_secondary text-center !mt-[2px]">
                      {t('bk_supported_format_document')}
                    </Typography>
                  </Stack>
                )}
              </StyledPaperFileUpload>
            ) : (
              <FilePreview
                params={field}
                fileDetails={fileDetails}
                url={fileDetails?.value}
                clearFieldValue={handleClearFile}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <Footer>
        <BKStyledButton
          fullWidth
          variant="contained"
          size="large"
          disabled={!enablePreview}
          endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
          onClick={() => setConfirmModalOpen(true)}>
          Confirm Details
        </BKStyledButton>
      </Footer>

      {confirmModalOpen && (
        <CommonBottomDrawerHoc isOpen={confirmModalOpen} handleClose={() => setConfirmModalOpen(false)}>
          <Stack spacing={2}>
            <Typography className="bk-headline3 text-bk_text_secondary">Are you sure to confirm?</Typography>
            <MetaDetailsContainer
              iconName="info_yellow_icon.svg"
              noteText="After submitting the details you will not be able to add/edit information"
              fontStyle="bK-body1"
              bgColor="bk_tag_orange_bg"
              textColor="bk_text_secondary"
            />
            <Grid container>
              <Grid item xs={6} paddingRight={'4px'}>
                <BKStyledButton fullWidth variant="text" size="large" onClick={() => setConfirmModalOpen(false)}>
                  No, Go back
                </BKStyledButton>
              </Grid>
              <Grid item xs={6} paddingLeft={'4px'}>
                <BKStyledButton fullWidth variant="contained" size="large" color="success" onClick={handleConfirm}>
                  Yes, Continue
                </BKStyledButton>
              </Grid>
            </Grid>
          </Stack>
        </CommonBottomDrawerHoc>
      )}
      <StyledRelGroupModal open={relGroupModalOpen} onClose={handleCloseRelGroupModal} width={width}>
        <section className="success-gif-container">
          <DisplayImage imageName="success_gif.gif" imageType="image" width="92px" height="92px" />
        </section>
        <Typography className="bk-headline3 text-bk_text_secondary mt-16">Completed Self Declaration</Typography>
      </StyledRelGroupModal>
    </>
  );
}
