export interface IProgressComponentProps {
  status: unknown;
}

export interface IOrderServiceRequest {
  actor_id: string;
  ext_case_id: string;
  account_id: string;
  tenantId: string;
  packages: string[];
  checks: any[];
}

export interface IAccountVerification {
  isVerified: boolean;
  source: string;
  verifiedAt: Date | null;
  verifiedBy: string;
}
export interface IAccount {
  verification: IAccountVerification;
  isRegistered: boolean;
  _id: string;
  account_name: string;
  account_type: string;
  account_role: string;
  email: string;
  phone: string;
  address: string;
  logo: string;
  gstin_no: string;
  company_code: string;
  tenantId: string;
  deletedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface IOrder {
  _id: string;
  orderId: string;
  order_service_request_list: IOrderServiceRequest[];
  account_id: IAccount;
  order_status: string;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface BgvOrdersNewTableProps {
  status: string;
}
export interface IProgressStatusProps {
  status: string;
  message: string;
}

export interface ICheckInProgress {
  invite: IProgressStatusProps;
  dataCollection: IProgressStatusProps;
  checkInProgress: IProgressStatusProps;
  quality: IProgressStatusProps;
  reportGenerated: IProgressStatusProps;
}

export interface ICurrentProgess {
  message: string;
  title: string;
  status: string;
}

export interface ICheckList {
  checkout_id: string;
  product_name: string;
  product_description: string;
  icon: string;
  check_status: IProgressStatusProps;
}

export interface IOrderCandidateListData {
  _id: string;
  name: string;
  jobRole: string;
  checkProgress: ICheckInProgress;
  currentProgess: ICurrentProgess;
  checkList: ICheckList[];
  createdAt: string;
  initiatedBy_image: string;
  order_status: string;
}

export interface IOrderJobRole {
  title: string;
  total_count: number;
}

export interface IGetCandidateFullStatus {
  title: string;
  color: string;
  bgColor: string;
  icon: string;
  outline: string;
  status: string;
  message: string;
}

export interface IOrderData {
  _id: string;
  orderId: string;
  order_title: string;
  account_id: IAccount;
  createdAt: string;
  initiatedBy_image: string;
  order_status: string;
  job_role: IOrderJobRole[];
  invoice_id: string;
  orderCompletionStatus: IOrderCompletionStatus;
}

// job.interface.ts

export interface IDraftOrderData {
  id: string;
  createdAt: string;
  initiatedBy_image: string;
  status: string;
  job_role: {
    [key: string]: number;
  };
}

export interface IMetaProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}
export const IMetaPropsInitial = {
  currentPage: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0,
};

export interface IOrderCompletionStatus {
  success_percentage: number;
  fail_percentage: number;
  warn_percentage: number;
  success_count: number;
  fail_count: number;
  warn_count: number;
  total_count: number;
}
