import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

export interface FooterProps {
  children: React.ReactNode;
  padding?: string;
}

export const Footer = (props: FooterProps) => {
  const { children, padding = '1 rem' } = props;
  return (
    <Box sx={{ flexGrow: 1, marginTop: '50px' }}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ top: 'auto', bottom: 0, maxWidth: '425px', margin: '0px auto', right: 'auto' }}>
        <Toolbar sx={{ padding: padding }}>{children}</Toolbar>
      </AppBar>
    </Box>
  );
};

export default Footer;
