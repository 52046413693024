import { devtools } from 'valtio/utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { proxy } from 'valtio';

interface IPackageDetails {
  id: string;
  title: string;
}

interface ICandidateDetails {
  id: string;
  jobRole: string;
  name: string;
  dataCollectionId: string;
  cdcStatus: string;
  sourceOfData: string[];
}

interface IDataCollectionSurvey {
  numberOfCandidates: number;
  dataCollected: {
    completed: number;
    inProgress: number;
    pending: number;
  };
  status: string;
  sentDate: string;
  displyaMessage: string;
}

interface IBulkUpload {
  numberOfCandidates: number;
  candidateDetailsUploaded: number;
  candidateDocumentsUploaded: number;
  documentMapped: number;
}

interface IPackage {
  packageDetails: IPackageDetails;
  candidateDetails: ICandidateDetails[];
  dataCollectionSurvey: IDataCollectionSurvey;
  bulkUpload: IBulkUpload;
}

interface ICurrentlyUploadFiles {
  imageUrl: string;
  imageName: string;
  imageSize: string;
  isImageCanceled: boolean;
  isCompleted: boolean;
  id: string;
}
interface IAlreadyUploadedFiles {
  imageUrl: string;
  imageName: string;
  imageSize: string;
  uploadedBy: string;
  date: number;
  id: string;
}
export type Status = 'PENDING' | 'COMPLETED';
export interface IStepperStatus {
  step: number;
  progress: Status;
}
export interface IStepperProps {
  step: number;
  description: string;
  status: string;
}
export const intialStepperStatus: IStepperStatus[] = [
  {
    step: 1,
    progress: 'PENDING',
  },
  {
    step: 2,
    progress: 'PENDING',
  },
  {
    step: 3,
    progress: 'PENDING',
  },
];

export interface CustomFile {
  file: File;
  uploadStatus: 'uploading' | 'uploaded' | 'canceled';
  uploadProgress: number;
}
export const ExcelUploadFileInitial: CustomFile = {
  file: {} as File,
  uploadStatus: 'uploading',
  uploadProgress: 0,
};
interface IEmployerCDCStoreType {
  listOfPackage: IPackage[];
  currentlyUploadFiles: ICurrentlyUploadFiles[];
  alreadyUploadedFiles: IAlreadyUploadedFiles[];
  toggle: boolean;
  excelToggle: boolean;
  request_id: string;
  request_role_id: string;
  activeStep: number;
  stepperStatus: IStepperStatus[];
  currentRole: string[];
  currentPackage: string;
  currentSelectedImage: string;
  currentSelectedImageName: string;
  currentSelectedDocumentTypeName: string;
  currentSelectedDocumentTypeLabel: string;
  currentSelectedDocumentTypeId: string;
  currentSelectedDocumentCandidate: string;
  currentSelectedDocumentCandidateId: string;
  selectedDocumentId: string;
  excelUploadFiles: CustomFile[];
  dataModel: boolean;
  totalCount: number;
  dataMapMeta: {
    currentPage: number | string;
    itemsPerPage: number | string;
    totalItems: number;
    totalPages: number;
    index?: number;
  };
  currentJobRoles: {
    title: string;
    total_count: number;
  }[];
  dataMapped: string;
  step3CurrentStatus: string;
  docListNumber: number;
  docUploadCount: number;
}
export const EmployerCDCStore: IEmployerCDCStoreType = proxy({
  listOfPackage: [
    {
      packageDetails: {
        id: '64b90fac21bcde682a8c4e78',
        title: 'OSCAR TRANSPORT Package 1',
      },
      candidateDetails: [
        {
          id: '6551f56b29d1536b944a22de',
          jobRole: 'Driver',
          name: 'Rudraksh Gupta',
          dataCollectionId: '65954b761870092bdf21f0ea',
          cdcStatus: 'Initiated',
          sourceOfData: ['NO DATA COLLECTED'],
        },
      ],
      dataCollectionSurvey: {
        numberOfCandidates: 1,
        dataCollected: {
          completed: 0,
          inProgress: 0,
          pending: 1,
        },
        status: 'SENT',
        sentDate: '2024-01-03T11:56:38.403Z',
        displyaMessage: 'Link sent successfully 0 days ago',
      },
      bulkUpload: {
        numberOfCandidates: 1,
        candidateDetailsUploaded: 1,
        candidateDocumentsUploaded: 5,
        documentMapped: 5,
      },
    },
  ],
  currentlyUploadFiles: [
    {
      imageUrl: '',
      imageName: '',
      imageSize: '',
      isImageCanceled: false,
      isCompleted: false,
      id: '',
    },
  ],
  alreadyUploadedFiles: [
    {
      imageUrl: '',
      imageName: '',
      imageSize: '',
      uploadedBy: '',
      date: 0,
      id: '',
    },
  ],
  currentJobRoles: [
    {
      title: '',
      total_count: 0,
    },
  ],
  toggle: true,
  excelToggle: true,
  request_id: '',
  request_role_id: '',
  activeStep: 0,
  stepperStatus: intialStepperStatus,
  currentRole: [''],
  currentPackage: '',
  currentSelectedImage: '',
  currentSelectedImageName: '',
  currentSelectedDocumentTypeName: '',
  currentSelectedDocumentTypeLabel: '',
  currentSelectedDocumentTypeId: '',
  currentSelectedDocumentCandidate: '',
  currentSelectedDocumentCandidateId: '',
  selectedDocumentId: '',
  totalCount: 0,
  dataModel: false,
  excelUploadFiles: [ExcelUploadFileInitial],
  dataMapMeta: {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
    totalPages: 1,
    index: 1,
  },
  dataMapped: '',
  step3CurrentStatus: '',
  docListNumber: 0,
  docUploadCount: 0,
});

devtools(EmployerCDCStore, { name: 'EmployerCDCStore', enabled: true });
