import { IValidateCandidate } from '@gigin-work-space/model';
import { proxy } from 'valtio';

// User dummy store creation
export const CandidateStore = proxy<{
  selectedCandidate: IValidateCandidate[] | [];
}>({ selectedCandidate: [] });

//Actions
export const setCandidate = (data: IValidateCandidate[]) => {
  CandidateStore.selectedCandidate = data;
};
