import { Typography } from '@mui/material';
import React from 'react';
import { DisplayImage } from '../common-ui';

const CaseCancelledPage: React.FC = () => (
  <div className="w-full h-[100vh] bg-bk_bg_primary_smoke flex flex-col items-center justify-center gap-4 p-4">
    <DisplayImage imageName="candidate-dc_404.svg" imageType="image" imageAlt="Error-Refresh Page" className="mb-4" />
    <Typography className=" bk-headline3 text-center">
      Looks like your BGV request has been cancelled. Please contact your HR regarding the same
    </Typography>
  </div>
);

export default CaseCancelledPage;
