import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface OtpTimerProps {
  minutes: number;
  seconds: number;
  text?: string;
  resendText: string;
  otpValid?: boolean;
  resendOtp: () => void;
}

export function OtpTimer(props: OtpTimerProps) {
  const { minutes, seconds, text, otpValid, resendText, resendOtp } = props;
  const [minuteValue, setMinuteValue] = useState(minutes);
  const [secondValue, setSecondValue] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondValue > 0) {
        setSecondValue(secondValue - 1);
      }

      if (secondValue === 0) {
        if (minuteValue === 0) {
          clearInterval(interval);
        } else {
          setSecondValue(59);
          setMinuteValue(minuteValue - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [secondValue, minuteValue]);

  const handleResend = () => {
    setMinuteValue(minutes);
    setSecondValue(seconds);
    resendOtp();
  };

  return (
    <div className="w-full mt-2">
      {otpValid && (secondValue > 0 || minuteValue > 0) ? (
        <p className="bk-sub-heading2 text-bk_text_primary">
          {`${text} `} {minuteValue < 10 ? `0${minuteValue}` : minuteValue}:
          {secondValue < 10 ? `0${secondValue}` : secondValue}
        </p>
      ) : (
        <p className="bk-sub-heading2 text-bk_tag_blue_light cursor-pointer w-fit" onClick={handleResend}>
          {resendText}
        </p>
      )}
    </div>
  );
}

export default OtpTimer;
