/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { IDialouge, IDialougeInitialState } from '@gigin-work-space/model';
import { proxy } from 'valtio';

//store to maintain toggle state
export const isModalOpenStore = proxy({
  isOpen: IDialougeInitialState,
});

//Actions to mutate the store

// Action to open the modal
export const handleModalOpen = (obj: IDialouge) => {
  isModalOpenStore.isOpen = obj;
};

// Action to close the modal
export const handleModalClose = () => {
  isModalOpenStore.isOpen = IDialougeInitialState;
};
