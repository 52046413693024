import { Typography } from '@mui/material';
import { BKStyledButton } from '../../utils';
import { DisplayImage } from '../common-ui';

export const ErrorPage = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-[100vh] bg-bk_bg_primary_smoke flex flex-col items-center justify-center gap-4 p-4">
      <DisplayImage imageName="candidate-dc_404.svg" imageType="image" imageAlt="Error-Refresh Page" />
      <Typography className="bk-headline3 text-bk_text_primary mt-2 text-center">
        We couldn't find the page you are looking for
      </Typography>
      <BKStyledButton variant="outlined" onClick={handleRefresh}>
        Refresh page
      </BKStyledButton>
    </div>
  );
};
