import { BKStyledOrdersLinearProgress } from '@gigin-work-space/common-ui';
import { Box } from '@mui/material';

export interface CheckProgressBarProps {
  progress: number;
}

export const CheckProgressBar = (props: CheckProgressBarProps) => {
  const { progress } = props;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BKStyledOrdersLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};
