import { SESSION_KEYS } from '@gigin-work-space/utils';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AdditionalProps {
  path: string; // Replace with the actual prop type
  children: React.ReactNode;
}

const ProtectedRoute = (props: AdditionalProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  //   const [userToken, setUserToken] = useState<string | null>(null);
  //   const { dataCollectionId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem(SESSION_KEYS.AUTH_TOKEN); // Get the token from sessionStorage
        if (!token) {
          const dataCollectionId = sessionStorage.getItem(SESSION_KEYS.DATA_COLLECTION_ID); // Get the token from sessionStorage

          navigate(`/${dataCollectionId}`);
        }
      } catch (error) {
        // setUserToken(null);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup effect to avoid memory leaks
    return () => {
      setIsLoading(false);
    };
  }, [navigate]);

  if (isLoading) {
    return <Typography className="w-full text-center">Loading...</Typography>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
