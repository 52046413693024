import { addressCBUsageStore, checkOtpVerifiedStore, handleUpdateFieldParameter } from '@gigin-work-space/store';
import {
  AADHAAR_NUMBER,
  BANK_IFSC,
  DRIVING_LICENSE_NUMBER,
  isValueMasked,
  PANCARD_NUMBER,
  PASSPORT_FILE_NUMBER,
  PASSPORT_NUMBER,
} from '@gigin-work-space/utils';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { ValidationError } from 'yup';
import { applyRuleMap, BKStyledTextField } from '../../../utils';

import { axiosInstance, endpoints } from '../../../services';

import { useParams } from 'react-router-dom';

export interface TextInputFieldProps {
  params: any;
}

export const TextInputField = (props: TextInputFieldProps) => {
  const { params } = props;
  const { addressCBValue } = useSnapshot(addressCBUsageStore);
  const { isOtpVerified } = useSnapshot(checkOtpVerifiedStore);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [value, setValue] = useState(params.field_value.value ? params.field_value.value : '');
  const [navParam, setNavParam] = useState(Object.values(useParams())[0]);
  const [isCurrentValueMasked, setIsCurrentValueMasked] = useState(false);
  const [maskedValue, setMaskedValue] = useState('');

  // function to format aadhaar number
  const formatAadhaarNumber = (aadhaarNumber: string) => {
    // Remove non-digit characters from the input value
    const newValue = aadhaarNumber?.replace(/\D/g, '');
    // Format Aadhaar number by inserting spaces
    const formattedValue = newValue?.replace(/(.{4})/g, '$1 ').trim();
    return formattedValue;
  };

  //   function to handle text change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (params?.field_name === AADHAAR_NUMBER) {
      const aadhaarValue = formatAadhaarNumber(value);
      setValue(aadhaarValue);
      return;
    }
    if (params?.field_name === PASSPORT_NUMBER) {
      const passportValue = value.toUpperCase();
      setValue(passportValue);
      return;
    }

    if (params?.field_name === PASSPORT_FILE_NUMBER) {
      const passportValue = value.toUpperCase();
      setValue(passportValue);
      return;
    }
    if (params?.field_name === BANK_IFSC) {
      const updatedFieldValue = value.toUpperCase();
      setValue(updatedFieldValue);
      return;
    }
    if (params?.field_name === DRIVING_LICENSE_NUMBER) {
      const updatedFieldValue = value.toUpperCase();
      setValue(updatedFieldValue);
      return;
    }
    setValue(params?.validation?.rule === PANCARD_NUMBER ? value.toUpperCase() : value);
  };

  const validateOptionalFieldsValue = async (trimmedValue: any, params: any) => {
    handleUpdateFieldParameter(
      params.field_id,
      {
        value: trimmedValue,
      },
      params.field_name,
    );
    if (trimmedValue === '') {
      return;
    }

    try {
      const check = await applyRuleMap(params.validation.rule)?.validate({
        value: trimmedValue,
      });

      setIsError(false);
      setErrorText('');
      handleUpdateFieldParameter(params.field_id, check, params.field_name);
    } catch (error) {
      const errorMessage = (error as ValidationError).errors.join(' ');
      setErrorText(errorMessage);
      setIsError(true);
    }
    return;
  };

  const setValueForAPI = async (
    isError: boolean,
    errorText: string,
    dataCollected: {
      value: string;
    },
  ) => {
    setIsError(isError);
    setErrorText(errorText);
    handleUpdateFieldParameter(params.field_id, dataCollected, params.field_name);
  };

  //   function to validate input
  const handleValidate = async () => {
    const trimmedValue = value?.trim();

    // Skip validation if value is masked
    if (isValueMasked(trimmedValue, params.field_name)) {
      await setValueForAPI(false, '', { value: value });
      return;
    }

    if (params.validation.is_optional) {
      return validateOptionalFieldsValue(trimmedValue, params);
    }

    try {
      // Validate the value against the given rule schema
      const check = await applyRuleMap(params.validation.rule)?.validate({
        value: params?.field_name === AADHAAR_NUMBER ? trimmedValue?.replace(/\D/g, '') : trimmedValue,
      });

      setIsError(false);
      setErrorText('');
      handleUpdateFieldParameter(params.field_id, check, params.field_name);
    } catch (error) {
      const errorMessage = (error as ValidationError).errors.join(' ');
      handleUpdateFieldParameter(params.field_id, { value: '' }, params.field_name);
      setErrorText(errorMessage);
      setIsError(true);
    }
  };

  useMemo(() => {
    setValue(params?.field_value?.value ? params?.field_value?.value : '');
    setIsCurrentValueMasked(isValueMasked(params?.field_value?.value, params?.field_name));
  }, [params]);

  const handleAutoFormFill = (event: any) => {
    handleChange(event);
  };
  useEffect(() => {
    const handleAutofill = () => {
      const inputElement = document.querySelector(`[name="${params?.field_name}"]`) as HTMLInputElement;
      if (inputElement) {
        setValue(inputElement.value);
      }
    };

    handleAutofill();
  }, [params?.field_name]);

  // function to handle API call and set field value
  const handleToggleValue = async () => {
    if (!maskedValue) {
      try {
        const dataCollectionId = navParam?.split('/').slice(2).join('/');

        setMaskedValue(value);

        // Perform an API call to get the actual value
        const response = await axiosInstance.get(
          `${endpoints.GET_PARAMETER_DETAIL_VALUE_FOR_DATA_COLLECTION}/${dataCollectionId}/${params.field_name}`,
        );

        if (response?.data) {
          // Set the unmasked value from the API response
          setValue(response.data.dataCollected.value);
        }
      } catch (error) {
        setErrorText('Failed to fetch field value.');
        setIsError(true);
        return;
      }
    } else {
      // Set the masked value
      setValue(maskedValue);
      setMaskedValue('');
    }
  };

  return (
    <>
      {/* This code has commented due to current requirement, please don't remove may need in future */}
      {/* TODO: remove en with current selected language params.field_name.value && */}
      {/* if field name is aadhaar and otp is verified, then don't show text field, show typography */}
      {
        // isOtpVerified ? (
        //   <Stack direction={'column'}>
        //     {params.field_name === AADHAAR_NUMBER ? (
        //       <div className="flex flex-col gap-2">
        //         <Typography className="bK-body3 text-bk_bg_dark">Aadhaar Verified</Typography>
        //         <Typography className="bk-headline2">{formatAadhaarNumber(value)}</Typography>
        //       </div>
        //     ) : (
        //       <Typography>{value}</Typography>
        //     )}
        //   </Stack>
        // ) :
        <BKStyledTextField
          label={params?.validation?.is_optional ? `${params?.label?.en} (optional)` : params?.label?.en}
          placeholder={params?.validation?.is_optional ? `${params?.label?.en} (optional)` : params?.label?.en}
          name={params?.field_name}
          variant="outlined"
          error={isError}
          helperText={isError && errorText}
          value={value}
          onChange={handleChange}
          disabled={addressCBValue || (params.should_encrypt_hash && value && isCurrentValueMasked && !maskedValue)}
          onBlur={(e) => {
            handleValidate();
            handleAutoFormFill(e);
          }}
          InputProps={{
            endAdornment:
              params.should_encrypt_hash && value && isCurrentValueMasked ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleValue}>
                    {maskedValue ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          inputProps={{
            maxLength:
              params?.validation?.rule === AADHAAR_NUMBER ? 14 : params?.validation?.rule === 'name' ? 50 : undefined,
          }}
        />
      }
    </>
  );
};
