import { handleUpdateFieldParameter, setAddressCheckBoxUsage } from '@gigin-work-space/store';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';

export interface AddressCheckboxInputFieldProps {
  params: any;
}

export const AddressCheckboxInputField = (props: AddressCheckboxInputFieldProps) => {
  const { params } = props;
  const [checked, setChecked] = React.useState(params?.field_value?.value.toLowerCase() === 'yes' ? true : false);

  // function to update payload and fields if address checkbox is changed
  const updateAddressFields = (isChecked: boolean) => {
    if (!isChecked) {
      params?.rule[0]?.copy_data?.forEach((element: any) => {
        handleUpdateFieldParameter(
          element.field_id,
          {
            value: '',
          },
          element.field_name,
        );
      });
      return;
    }
    params?.rule[0]?.copy_data?.forEach((element: any) => {
      handleUpdateFieldParameter(element.field_id, element.field_value, element.field_name);
    });
  };

  // function to handle checkbox toggel/change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);
    setAddressCheckBoxUsage(checked, params?.rule[0]?.copy_data);
    handleUpdateFieldParameter(
      params.field_id,
      {
        value: checked ? 'Yes' : 'No',
      },
      params.field_name,
    );
    updateAddressFields(checked);
  };

  // effect to set values of checkbox and related field on page load
  React.useEffect(() => {
    setAddressCheckBoxUsage(checked, params?.rule[0]?.copy_data);
    handleUpdateFieldParameter(
      params.field_id,
      {
        value: checked ? 'Yes' : 'No',
      },
      params.field_name,
    );
    updateAddressFields(checked);
  }, []);

  useMemo(() => {
    setChecked(params?.field_value?.value.toLowerCase() === 'yes' ? true : false);
  }, [params]);

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />}
      label={params.validation.is_optional ? `${params.label.en}` : params.label.en}
    />
  );
};
