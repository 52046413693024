import { useBytesToMB } from '@gigin-work-space/utils';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Stack, Typography } from '@mui/material';
import { BKStyledIconButton, StyledPaperFileUpload } from '../../../utils';
import DisplayImage from '../display-image';
import ImageWithCredentials from '../protected-image';

export interface FilePreviewProps {
  width?: number | string;
  height?: number | string;
  fileDetails: any;
  url: string;
  params: any;
  mode?: string;
  clearFieldValue?: (value: any) => void;
}

export const FilePreview = (props: FilePreviewProps) => {
  const { params, fileDetails, url, mode = 'edit', clearFieldValue } = props;
  const { fileSize, fileUnit } = useBytesToMB(fileDetails?.file_size);

  return (
    <StyledPaperFileUpload
      elevation={0}
      sx={{
        padding: '0.5rem',
      }}>
      {mode !== 'edit' && <Typography className="bK-body3 text-bk_text_primary pb-2">{params.label.en}</Typography>}
      <Stack spacing={1}>
        {mode === 'edit' && (
          <div className="flex items-center justify-between">
            <Typography className="bK-body3 text-bk_text_primary">{params.label.en}</Typography>
            <BKStyledIconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="clear file uploaded button"
              onClick={clearFieldValue}>
              <CancelOutlinedIcon fontSize="medium" />
            </BKStyledIconButton>
          </div>
        )}
        {fileDetails?.data_type === 'application/pdf' ? (
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ marginTop: '0px!important', flexWrap: 'wrap', width: '100%' }}>
            <DisplayImage imageName="pdf_image.svg" imageType="image" imageAlt={fileDetails?.file_name} />
            <Stack sx={{ maxWidth: '75%' }}>
              <Typography className="truncate">{fileDetails?.file_name}</Typography>
              <Typography>{`${fileSize} ${fileUnit}`}</Typography>
            </Stack>
          </Stack>
        ) : fileDetails?.data_type === 'application/zip' ? (
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ marginTop: '0px!important', flexWrap: 'wrap', width: '100%' }}>
            <DisplayImage imageName="zip_icon.svg" imageType="image" imageAlt={fileDetails?.file_name} />
            <Stack sx={{ maxWidth: '75%' }}>
              <Typography className="truncate">{fileDetails?.file_name}</Typography>
              <Typography>{`${fileSize} ${fileUnit}`}</Typography>
            </Stack>
          </Stack>
        ) : (
          <ImageWithCredentials imageUrl={url} imageAlt="Image Preview" className="w-full object-contain h-32" />
        )}
      </Stack>
    </StyledPaperFileUpload>
  );
};
