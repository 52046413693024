import { handleUpdateFieldParameter } from '@gigin-work-space/store';
import {
  DOCUMENT_UPLOAD,
  IMAGE_AND_FILE_ACCEPTED,
  IMAGE_UPLOAD,
  MAX_FILE_SIZE,
  ONLY_IMAGE_ACCEPTED,
  ONLY_ZIP_FILE_ACCEPTED,
  useBytesToMB,
} from '@gigin-work-space/utils';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { axiosInstance, endpoints } from '../../../services';
import { StyledPaperFileUpload } from '../../../utils';
import DisplayImage from '../display-image';
import { FilePreview } from './file-preview';

export interface FileUploadProps {
  width?: number | string;
  height?: number | string;
  params: any;
}

const initialFileValue = {
  value: '',
  data_type: 'file',
  media_id: '',
  file_name: '',
  file_size: '',
};

// 5 * 1024 * 1024 - 5 MB in bytes

export const FileUpload = (props: FileUploadProps) => {
  const { width = '100%', height = 68, params } = props;
  const [loading, setLoading] = useState(false);
  const [enablePreview, setEnablePreview] = useState(!params?.field_value?.file_name ? false : true);
  const [fileDetails, setFileDetails] = useState(params?.field_value?.value ? params?.field_value : initialFileValue);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noDrag: true,
    accept:
      params?.validation?.rule === DOCUMENT_UPLOAD && params?.field_data_type?.toLowerCase() === 'file'
        ? IMAGE_AND_FILE_ACCEPTED
        : params?.field_data_type?.toLowerCase() === 'zip'
        ? ONLY_ZIP_FILE_ACCEPTED
        : ONLY_IMAGE_ACCEPTED,
    multiple: false,
  });

  const { fileSize, fileUnit } = useBytesToMB(MAX_FILE_SIZE);

  // Function to clear file on click of cross
  const handleClearFile = () => {
    setEnablePreview(false);
    setFileDetails(initialFileValue);
    handleUpdateFieldParameter(params.field_id, initialFileValue, params.field_name);
  };

  // function to upload file on S3 and return image url to parent
  const uploadFileOnS3 = useCallback(async () => {
    const acceptedFile = acceptedFiles[0];
    const ALLOWED_MIME_TYPES = "['image/jpeg', 'image/png', 'application/pdf', 'application/zip']";
    if (!ALLOWED_MIME_TYPES.includes(acceptedFile.type)) {
      enqueueSnackbar(`Please upload a file in PNG, JPEG, JPG, or PDF format only.`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles[0].size > MAX_FILE_SIZE) {
      enqueueSnackbar(`Maximum file size is ${fileSize}${fileUnit} only`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      setLoading(true);
      // TODO: for now type and typeid is dummy, will change later
      const mediaData = {
        field_id: params?.field_id || '',
        isEncrypted: true,
        filePathContext: 'bgv-check-file-upload',
        isTenantIsolated: true,
        isPrivate: true,
        shouldCompress: false,
      };

      const body = new FormData();
      body.append('file', acceptedFiles[0]);
      body.append('field_id', params.field_id);
      body.append('media', JSON.stringify(mediaData));

      const response = await axiosInstance.post(`${endpoints.POST_FILE_S3}`, body);

      // TODO: Handle success & error scenarios
      if (response.status === 201) {
        setFileDetails(response.data.data);
        handleUpdateFieldParameter(params.field_id, response.data.data, params.field_name);
        const fieldElement = document.getElementById(params.field_id) as HTMLElement;
        fieldElement.style.borderColor = 'inherit';
        setEnablePreview(true);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false after upload (success or failure)
    }
  }, [acceptedFiles]);

  // Side Effect
  useEffect(() => {
    if (acceptedFiles.length) {
      uploadFileOnS3();
    }
  }, [uploadFileOnS3]);

  useMemo(() => {
    setEnablePreview(!params?.field_value?.file_name ? false : true);
    setFileDetails(params?.field_value?.value ? params?.field_value : initialFileValue);
  }, [params]);

  if (enablePreview) {
    return (
      <FilePreview
        params={params}
        fileDetails={fileDetails}
        url={fileDetails?.value}
        clearFieldValue={handleClearFile}
      />
    );
  }

  return (
    <StyledPaperFileUpload
      id={params.field_id}
      elevation={0}
      sx={{
        width: width,
        height: 'auto',
        minHeight: height,
      }}>
      {loading ? (
        <div className="w-full flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <Stack spacing={1} justifyContent={'center'} alignItems={'center'} {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Stack spacing={1} direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <DisplayImage imageName="cloud_upload_icon.svg" imageType="icon" width="20px" height="20px" />
            <Typography className="bk-sub-heading3 text-bk_text_secondary text-center">{params.label.en}</Typography>
          </Stack>
          <Typography className="bK-body3 text-bk_text_secondary text-center" sx={{ marginTop: '2px !important' }}>
            {params?.validation?.rule === IMAGE_UPLOAD
              ? t('bk_supported_format_image')
              : t('bk_supported_format_document')}
          </Typography>
        </Stack>
      )}
    </StyledPaperFileUpload>
  );
};

export default FileUpload;
