import { proxy } from 'valtio';

// interface ITeamStore {
//   id: string;
// }
// Store to maintain Tema details for that account
export const teamStore = proxy<{ id: string; hasRefresh: boolean }>({
  id: '',
  hasRefresh: false,
});
