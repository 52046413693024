import { proxy } from 'valtio';

// Store to maintain report data
export const reportDataStore = proxy<{ reportData: any }>({ reportData: null });

/* ------------Actions-------------*/

// Action to set the value of report data on get api call
export const setReportData = (reportData: any) => {
  reportDataStore.reportData = reportData;
};
