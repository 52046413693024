import { COLORS, MAX_SCREEN_BREAKPOINT } from '@gigin-work-space/utils';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  RadioGroup,
  Select,
  TextField,
  Theme,
  styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import OtpInput from 'react18-input-otp';

// Main Conainer Body
export const BKMobileStyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 425,
  minHeight: '100vh',
  position: 'relative',
  backgroundColor: COLORS.bk_bg_primary_smoke,
}));

// Styled Appbar
export const BKStyledAppbar = styled(AppBar)(({ theme }) => ({
  background: COLORS.bk_bg_dark,
  color: COLORS.bk_bg_primary,
}));

// Styled Drawer Bottom
export const BKStyledBottomDrawer = styled(Drawer)(({ theme, width }: { theme?: Theme; width: number }) => ({
  '& .MuiPaper-root': {
    maxWidth: width <= MAX_SCREEN_BREAKPOINT ? '100%' : '425px',
    width: '100%',
    maxHeight: '80vh',
    top: 'auto',
    left: '50%',
    transform: 'translate(-50%, 0) !important',
    padding: '1.5rem',
    borderRadius: '16px 16px 0px 0px !important',
    overflow: 'auto',
  },
}));

// Styled IconButton
export const BKStyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiIconButton-root:focus, &.MuiIconButton-root:active': {
    outline: 'none', // Remove focus outline
    boxShadow: 'none', // Remove box shadow
  },
}));

// Styled Button
export const BKStyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  '&.MuiButton-root': { textTransform: 'none' },
  '&.MuiButton-root:focus, &.MuiButton-root:active': {
    outline: 'none', // Remove focus outline
    boxShadow: 'none', // Remove box shadow
  },
}));

// Styled TextField
export const BKStyledTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    width: '100%',
    background: COLORS.bk_bg_primary,
  },
  '& .MuiFormLabel-root': {
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiFormLabel-root.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiInputBase-root': {
    height: '44px',
    borderRadius: '8px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    padding: '0 8px',
    background: COLORS.bk_bg_primary_smoke,
    margin: 0,
  },
}));

// Styled Select
export const BKStyledSelectGroup = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiFormLabel-root.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
}));

// Styled Select
export const BKStyledSelect = styled(Select)(({ theme }) => ({
  height: '44px',
  borderRadius: '8px',
  background: COLORS.bk_bg_primary,
}));

// Styled Radio group - box radio
export const BKStyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  '&.MuiFormGroup-root': {
    margin: '0.5rem 0.75rem 0',
    gap: 10,
  },
  '& .MuiRadio-root': {
    display: 'none',
  },
}));

// Styled box Radio Form label
export const BKStyledRadioFormLabel = styled(FormControlLabel)(
  ({ theme, selected }: { theme?: Theme; selected: boolean }) => ({
    background: selected ? COLORS.bk_tag_blue : COLORS.bk_bg_primary,
    border: `1px solid ${COLORS.bk_stroke_primary}`,
    borderRadius: 8,
    minWidth: 68,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: selected ? COLORS.bk_bg_primary : COLORS.bk_text_secondary,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
    },
  }),
);

// Styled Date Picker
export const BKStyledDatePicker = styled(DatePicker)(({ theme, error }: { theme?: Theme; error: boolean }) => ({
  background: COLORS.bk_bg_primary,
  minWidth: '100px !important',
  width: '100% !important',
  '& .MuiFormLabel-root': {
    transform: 'translate(14px, 9px) scale(1)',
    paddingTop: '2px',
    color: error ? COLORS.bk_alert_failure : 'inherit',
  },

  '& .MuiFormLabel-root.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiInputBase-root': {
    height: '44px',
    borderRadius: '8px',
    color: error ? COLORS.bk_alert_failure : 'inherit',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? COLORS.bk_alert_failure : 'inherit',
    },
  },

  // .css-1d3z3hw-MuiOutlinedInput-notchedOutline
  '& .MuiFormHelperText-root.Mui-error': {
    padding: '0 8px',
    background: COLORS.bk_bg_primary_smoke,
    margin: 0,
  },
}));

// custom style for otp input box
export const StyledOTPInput = styled(OtpInput)(({ theme, isOtpCorrect }: { theme: Theme; isOtpCorrect: boolean }) => ({
  width: '44px',
  height: '44px',
  borderRadius: '8px',
  margin: '0 4px',
  justifyContent: 'center',
  border: isOtpCorrect ? theme.customTheme?.border?.normal_black : theme.customTheme?.border?.normal_error,
  '&:focus-within': {
    border: isOtpCorrect
      ? `${theme.customTheme?.border?.normal_focus} !important`
      : theme.customTheme?.border?.normal_error,
  },
  '& input': {
    width: '85% !important',
    height: '90%',
    border: 'none !important',
    outline: 'none !important',
  },
}));

// Resend OTP Container
export const StyledOTPContainerResend = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: theme.customTheme?.gap?.custom_4,
  marginTop: '0.5rem !important',
}));

// Styled Paper Card for data collection list
export const StyledPaperCard = styled(Paper)(({ status }: { status?: string }) => ({
  width: '100%',
  height: '100%',
  minHeight: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '4px',
  padding: '1rem',
  boxShadow: status?.toLowerCase() === 'completed' ? 'none' : '0px 4px 10px -2px rgba(13, 21, 38, 0.07)',
  border: `1px solid ${COLORS.bk_stroke_secondary}`,
  borderRadius: 12,
  cursor: 'pointer',
}));

// Styled Paper Card for file upload with dynamic widthsent via props
export const StyledPaperFileUpload = styled(Paper)(({ theme }) => ({
  padding: '0.75rem',
  border: `1px dashed ${COLORS.bk_action_disabled}`,
  borderRadius: 8,
  cursor: 'pointer',
}));

// styled Divider for view/confirm page divider
export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderWidth: '1px',
  borderColor: COLORS.bk_stroke_primary,
}));

// styled Dialog Modal for Rel Group Completion popup
export const StyledRelGroupModal = styled(Dialog)(({ theme, width }: { theme?: Theme; width: number }) => ({
  '& .MuiPaper-root': {
    maxWidth: width <= MAX_SCREEN_BREAKPOINT ? '90%' : '400px',
    width: '100%',
    minHeight: '20vh',
    height: 'fit-content',
    padding: '1.5rem',
    borderRadius: '16px !important',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'visible',
  },
}));
