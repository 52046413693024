import { COLORS } from '@gigin-work-space/utils';
import { ThemeOptions } from '@mui/material';

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: COLORS.bk_action_primary,
    },
    secondary: {
      main: COLORS.bk_tag_blue,
    },
  },

  typography: {
    fontFamily: ['Inter'].join(','),
  },

  customTheme: {
    width: {
      custom_40: '40px',
      custom_90: '90px',
      custom_94: ' 94px',
      custom_300: '300px',
    },
    height: {
      custom_56: '56px',
      custom_4: '4px',
    },
    fontSize: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
      custom_34: '34px',
    },

    fontWeight: {
      light: '300px',
      normal: '400px',
      medium: '500px',
      bold: '600px',
      bolder: '700px',
    },
    color: {
      white: '#ffffff',
      authentication_container: '#fcfaf6;',
      authentication_divider: '#878587',
      black_rgb87: 'rgba(0, 0, 0, 0.87)',
      black_rgb23: 'rgba(0, 0, 0, 0.23)',
      black_rgb6: 'rgba(0, 0, 0, 0.6)',
      company_ribbon: 'rgba(33, 150, 243, 0.04)',
      link_text: '#0085ff',
      normal_text: '#242224',
      error_text: '#d32f2f',
      success_text: '#2e7d32',
      orange_warning: '#ed6c02',
      orange: '#ff9800',
      bk_text_primary: '#201D22',
      bk_text_secondary: '#55505A',
      bk_bg_primary: '#FFFFFF',
      bk_bg_primary_smoke: '#FAFBFD',
      bk_bg_secondary: '#FAF9FC',
      bk_bg_dark: '#545658',
      bk_stroke_primary: '#DCD9DD',
      bk_stroke_secondary: '#EBEBEB',
      bk_stroke_divider: '#C8CBCE',
      bk_action_primary: '#6F35F2',
      bk_action_pressed: '#660CD9',
      bk_action_disabled: '#BEBBC2',
      bk_alert_success: '#048218',
      bk_alert_failure: '#D94415',
      bk_alert_new: '#E6971F',
      bk_alert_warning: '#EE6E12',
      bk_tag_yellow: '#CCAF16',
      bk_tag_yellow_bg: '#FEFCF2',
      bk_tag_orange: '#B98113',
      bk_tag_orange_bg: '#FEFBF5',
      bk_tag_blue: '#0053F3',
      bk_tag_blue_light: '#167FCC',
      bk_tag_blue_bg: '#F3F8FC',
      bk_tag_green_bg: '#E8FCF2',
      bk_tag_action_bg: '#F1ECFD',
      bk_tag_gray_bg: '#F1F1F1',
      bk_table_header_text: 'rgba(0, 0, 0, 0.4);',
    },
    boxShadow: {
      authentication_paper: '1px solid rgba(0, 0, 0, 0.23)',
    },
    border: {
      normal_black: '1px solid rgba(0, 0, 0, 0.23)',
      normal_error: '1px solid #d32f2f',
      normal_selected: '1px solid #2196f3',
      normal_focus: '1px solid #6F35F2',
    },
    borderRadius: {
      authentication_paper: '0px 120px',
      custom_4: '4px',
      custom_16: '16px',
      custom_32: '32px',
    },
    margin: {
      header_emoji: '-8px',
      horizontal_otp_input: '4px',
      signup_option_err: '0 0 0.25rem 14px',
    },
    padding: {
      horizontal_otp_input: '12px',
    },
    gap: {
      custom_4: '4px',
      custom_10: '10px',
      custom_6_percentage: '6%',
      custom_15: '15px',
    },
  },
};
